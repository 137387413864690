import React, { useEffect, useState } from 'react'
import useMedia from 'use-media'
import { useCountUp } from 'react-countup'

const Requests = () => {
  const isWide = useMedia({ minWidth: 1000 })

  const [number, setNumber] = useState(5000)

  const { countUp, update } = useCountUp({
    start: 5000,
    end: number,
    separator: ',',
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setNumber(number + 5100 + Math.floor(Math.random() * 1000))
    }, 1000)

    return () => clearInterval(interval)
  })

  useEffect(
    () => {
      update(number)
    },
    [number, update]
  )

  return (
    <div className="u-bg-black2 u-flex u-items-center u-flex-col u-rounded u-shadow u-p-md">
      <h2
        className="u-text-p1 u-mx-auto u-mt-md u-mb-md u-text-center u-flex u-justify-center"
        style={{
          width: isWide ? '350px' : '220px',
        }}
      >
        <span
          className="u-text-mark u-pb-md"
          style={{ width: isWide ? '350px' : '220px' }}
        >
          {countUp}
        </span>
      </h2>
      <h4 className="u-text-p6 u-mb-md u-text-center" style={{ width: '90%' }}>
        times our Chat Widget was displayed worldwide since you entered this
        page
      </h4>
    </div>
  )
}

export default Requests
