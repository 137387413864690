import React from 'react'
import { string, object, node } from 'prop-types'
import VideoDecoration1 from '../assets/icons/video-background-blob-1.svg'
import VideoDecoration2 from '../assets/icons/video-background-blob-2.svg'
import { Video } from '.'

const GetStarted = ({
  title,
  subtitle,
  description,
  customButton,
  customImage,
}) => {
  return (
    <section className="o-container u-Mb-md md:u-mb-0">
      <div className="o-feature">
        <div className="u-mb-xs">
          <h3 className="u-text-gray-600">{title}</h3>
          <h1 className="u-text-p2">{subtitle}</h1>
          <p className="u-text-gray-600 u-text-p5">{description}</p>
          {customButton ? (
            customButton
          ) : (
            <a
              href={process.env.GATSBY_CONSOLE_URL}
              className="c-btn u-w-fit u-mt-xs v--livechat u-text-white u-text-p6-bold"
            >
              Get started
            </a>
          )}
        </div>
        {customImage ? (
          customImage
        ) : (
          <div className="u-relative u-overflow-x">
            <VideoDecoration1 className="u-absolute u-ml-xl u-mt-xs md:u-mt-xl video-decoration-1" />
            <VideoDecoration2 className="u-absolute u-ml-xl u-mt-xs md:u-mt-xl video-decoration-2" />
            <Video
              sideView
              preview="developerProgramExplainerVideo"
              src={process.env.GATSBY_DEV_PROGRAM_VIDEO_URL}
            />
          </div>
        )}
      </div>
    </section>
  )
}

GetStarted.propTypes = {
  title: string,
  subtitle: object,
  description: object,
  customButton: node,
  customImage: node,
}

export default GetStarted
