import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

const Quantity = ({ value, description }) => {
  const isWide = useMedia({ minWidth: 1000 })

  return (
    <div className="u-bg-black2 u-flex u-items-center u-flex-col u-rounded u-shadow u-p-xl">
      <h2
        className="u-text-p1 u-mx-auto u-mt-xl u-mb-md u-text-center u-flex u-justify-center"
        style={{
          width: isWide ? '350px' : '220px',
        }}
      >
        <span
          className="u-text-mark u-pb-md"
          style={{ width: isWide ? '350px' : '220px' }}
        >
          {value}
        </span>
      </h2>
      <h4 className="u-text-p6 u-mb-xl u-text-center" style={{ width: '90%' }}>
        {description}
      </h4>
    </div>
  )
}

Quantity.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
}

export default Quantity
