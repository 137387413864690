import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import InfoIcon from '../../assets/icons/info.svg'
import CheckIcon from '../../assets/icons/check.svg'

const SubTable = ({ title, content, withBorders, id }) => {
  const isWide = useMedia({ minWidth: 850 })
  return (
    <>
      <div
        id={id}
        style={{
          display: 'block',
          position: 'relative',
          top: '-80px',
          visibility: 'hidden',
        }}
      />
      <section className="o-container u-Pd-xs">
        <h3 className="u-Mt-lg u-Mb-xs u-text-p6-bold">{title}</h3>
        <div
          className={`${withBorders ? 'u-border-t' : ''}`}
          style={{ borderColor: '#505050' }}
        >
          {content.map((el, index) => {
            return (
              <div
                key={`${el.label}-${index}`}
                className={`t-p-table-categories ${
                  withBorders ? 'u-border-b' : index % 2 ? 'u-bg-black-3' : ''
                }`}
              >
                <div className="t-p-cell-xl">
                  <p className="u-text-p7 u-Py-2xs u-Pl-xs u-mb-0">
                    {el.label}
                    <span className="t-p-hint t-tooltip">
                      {el.info && (
                        <Fragment>
                          <InfoIcon />
                          <span className="t-tooltiptext-right">{el.info}</span>
                        </Fragment>
                      )}
                    </span>
                  </p>
                </div>
                {el.values.map((value, index) => {
                  return (
                    <div
                      key={`${value}-${index}`}
                      className="t-p-cell-md u-text-center u-text-p8 u-Py-2xs"
                      style={{
                        marginLeft: !isWide && index === 0 ? 'auto' : '',
                      }}
                    >
                      {value === 'yes' ? (
                        <CheckIcon style={{ fill: '#FF5100' }} />
                      ) : (
                        value
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

SubTable.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.array,
  withBorders: PropTypes.bool,
}

export default SubTable
