import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Slice = ({
  name,
  description,
  link,
  externalLink,
  withButton,
  cta = 'Learn more',
}) => {
  return (
    <div className="u-h-full u-flex u-flex-col u-justify-between">
      <div>
        <h4 className="u-text-p4 u-mb-md">{name}</h4>
        <p className="u-text-p6 u-text-gray-900">{description}</p>
      </div>
      {externalLink ? (
        <a
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          className={`u-w-fit u-mt-xs u-text-p6-bold ${
            withButton
              ? 'c-btn v--livechat u-text-white'
              : 'c-link u-text-livechat u-mb-xs'
          }`}
        >
          {cta}
        </a>
      ) : (
        <Link
          to={link}
          className={`u-w-fit u-mt-xs u-text-p6-bold ${
            withButton
              ? 'c-btn v--livechat u-text-white'
              : 'c-link u-text-livechat u-mb-xs'
          }`}
        >
          {cta}
        </Link>
      )}
    </div>
  )
}

Slice.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  externalLink: PropTypes.string,
  withButton: PropTypes.bool,
  cta: PropTypes.string,
}

export default Slice
