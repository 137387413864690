import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMedia from 'use-media'
import Image from './Image'

const Update = ({ slug, title, author, date, excerpt, image, index }) => {
  const isWide = useMedia({ minWidth: 820 })
  const isSmall = useMedia({ maxWidth: 420 })
  const showExcerpt = useMedia({ maxWidth: 600 })
  const showFullExcerpt = useMedia({ minWidth: 900 })

  return (
    <section className={`o-container ${isWide ? 'u-Mb-xl' : 'u-Mb-md'}`}>
      <div
        className={`u-relative u-flex ${
          isWide ? 'u-items-center' : 'u-items-start'
        }`}
      >
        <Image image={image} title={title} index={index} slug={slug} />

        <div style={{ width: '55%', marginLeft: '3%' }}>
          <Link
            to={`/updates/${slug}/`}
            className={`u-hover-pointer u-block u-text-white ${
              isWide
                ? 'u-text-p4 u-mb-md'
                : isSmall
                ? 'u-text-p7-bold u-mb-xs'
                : 'u-text-p5-bold u-mb-xs'
            }`}
          >
            {title}
          </Link>
          <p
            className={`${
              isWide ? 'u-text-p6' : 'u-text-p7 u-mb-0'
            } u-color-gray-2`}
          >
            {!isSmall && <Fragment>{author} in Developer Program, </Fragment>}
            <span style={{ whiteSpace: 'nowrap' }}>on {date}</span>
          </p>
          <p className={`u-text-p6 ${showExcerpt ? 'u-hidden' : ''}`}>
            {showFullExcerpt ? excerpt : `${excerpt.split('.')[0]}.`}
          </p>
        </div>
      </div>
    </section>
  )
}

Update.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.object,
  marginBottom: PropTypes.bool,
  date: PropTypes.string,
  slug: PropTypes.string,
  index: PropTypes.number,
}

export default Update
