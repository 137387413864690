import React, { useState } from 'react'
import ItemsCarousel from 'react-items-carousel'
import { array } from 'prop-types'
import { useMedia } from 'use-media'
import ArrowLeft from '../assets/icons/arrow-left.svg'
import ArrowRight from '../assets/icons/arrow-right.svg'
import Image from './Image'

const onClick = link => window.open(link)

const Carousel = ({ apps }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const isWide = useMedia({ minWidth: '1024px' })
  const isMobile = useMedia({ minWidth: '700px' })

  return (
    <div className="o-container unselectable">
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={isWide ? 3 : isMobile ? 2 : 1}
        gutter={20}
        infiniteLoop
        leftChevron={
          <span className="u-py-md u-px-md u-bg-black-4 u-border-black-4 u-border u-rounded-xl carousel-arrow">
            <ArrowLeft className="u-absolute left" />
          </span>
        }
        rightChevron={
          <span className="u-py-md u-px-md u-bg-black-4 u-border-black-4 u-border u-rounded-xl carousel-arrow">
            <ArrowRight className="u-absolute right" />
          </span>
        }
        outsideChevron
        chevronWidth={60}
        classes={{
          leftChevronWrapper: 'u-hover-default',
          rightChevronWrapper: 'u-hover-default',
        }}
      >
        {apps.map(({ title, description, icon, link, alt }, i) => {
          return (
            <div
              key={`${title}-${i}`}
              className="u-h-full u-flex u-flex-col u-justify-between u-Py-md"
            >
              <div
                key={`${title}-${i}`}
                className="u-bg-black-7 u-rounded u-shadow u-p-xl u-text-left u-h-full u-flex u-flex-col u-justify-between u-hover-lift u-hover-pointer u-mt-md md:u-m-0"
                onClick={() => onClick(link)}
              >
                <div
                  className="u-flex u-Pb-xs"
                  style={{ alignItems: 'center' }}
                >
                  <Image
                    alt={alt}
                    type={icon}
                    wrapperStyles={{
                      minWidth: '36px',
                    }}
                  />
                  <h3
                    className="u-Ml-xs u-text-p5 u-font-bold"
                    style={{ marginBottom: '0px' }}
                  >
                    {title}
                  </h3>
                </div>
                <div>
                  <h4 className="u-text-p7">{description}</h4>
                </div>
                <a
                  href={link}
                  className="c-link u-text-livechat u-text-p6-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                >
                  Learn more
                </a>
              </div>
            </div>
          )
        })}
      </ItemsCarousel>
    </div>
  )
}

Carousel.propTypes = {
  apps: array,
}

export default Carousel
