import React from 'react'
import PropTypes from 'prop-types'

import BackIcon from '../../assets/icons/back.svg'

const Back = ({ setDropdown, label }) => {
  return (
    <li className="c-navbar__item" onClick={() => setDropdown(undefined)}>
      <button className="c-navbar__link u-bg-black u-text-white u-hover-default u-flex u-items-center u-w-full">
        <BackIcon className="u-mr-xs" />
        <span className="u-mt-2xs">{label}</span>
      </button>
    </li>
  )
}

Back.propTypes = {
  label: PropTypes.string,
  setDropdown: PropTypes.func,
}

export default Back
