import React from 'react'
import PropTypes from 'prop-types'

const Articles = ({ title, items }) => {
  return (
    <section className="o-container u-Mb-xl">
      <h3 className="u-text-livechat u-text-p5-bold u-Mb-md">{title}</h3>

      <div className="o-columns v--three v--responsive v--sparse">
        {items?.map((el, i) => {
          return (
            <div
              key={`${el.title}-${i}`}
              className="u-h-full u-flex u-flex-col u-justify-between"
            >
              <div>
                <h4 className="u-text-p4 u-mb-xs">{el.title}</h4>
                <p className="u-text-p6">{el.description}</p>
              </div>
              <a
                href={el.link}
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-text-p6-bold c-link u-text-livechat u-mb-xs"
              >
                Read the article
              </a>
            </div>
          )
        })}
      </div>
    </section>
  )
}

Articles.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      link: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Articles
