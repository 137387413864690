import React from 'react'
import PropTypes from 'prop-types'

import './block.less'

const Block = ({ index, title, description, image, fullWidth }) => {
  const blockStyles = {
    gridRow: fullWidth ? Math.floor(index / 2) + 1 : undefined,
  }

  return (
    <div
      className={`block u-bg-black2 u-overflow-hidden u-rounded ${
        fullWidth ? 'block--full-width' : ''
      }`}
      style={blockStyles}
    >
      <div className="block__content u-Px-sm lg:u-Px-lg u-Pt-sm lg:u-Pt-lg">
        <div className="block-index u-bg-livechat u-Mb-md">{index}</div>

        <h2 className="u-text-p4">{title}</h2>
        <h3 className="u-text-p6 u-w-full">{description}</h3>
      </div>

      <div className="block__image">{image}</div>
    </div>
  )
}

Block.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  fullWidth: PropTypes.bool,
  image: PropTypes.node,
}

export default Block
