import React from 'react'
import { string, array, bool } from 'prop-types'
import { Link } from 'gatsby'

const Features = ({ features, label, gray, noPadding, verticalMargin }) => {
  return (
    <div className={`o-container u-Mb-md ${noPadding ? 'u-p-0' : ''}`}>
      {label && (
        <h2 className="u-text-livechat u-text-p5-bold u-Mb-xs">{label}</h2>
      )}
      <section
        className={`${
          features.length > 2 ? 'v--three' : 'v--two'
        } o-columns v--responsive`}
      >
        {features?.map(
          (
            { title, description, link, externalLink, cta = 'Learn more' },
            i
          ) => {
            return (
              <div
                key={`${externalLink}-${i}`}
                className={`u-flex u-flex-col u-justify-between ${
                  verticalMargin ? 'u-Mx-xs' : ''
                }`}
              >
                <h2 className="u-text-p4">{title}</h2>
                <h3
                  className={`${
                    gray ? 'u-text-gray-800' : ''
                  } u-text-p6  u-w-full u-mb-md`}
                >
                  {description}
                </h3>
                {externalLink ? (
                  <a
                    href={externalLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={`c-link u-text-livechat u-text-p6-bold`}
                  >
                    {cta}
                  </a>
                ) : (
                  <Link
                    to={link}
                    className={`c-link u-text-livechat u-text-p6-bold`}
                  >
                    {cta}
                  </Link>
                )}
              </div>
            )
          }
        )}
      </section>
    </div>
  )
}

Features.propTypes = {
  features: array,
  label: string,
  gray: bool,
  noPadding: bool,
  verticalMargin: bool,
}

export default Features
