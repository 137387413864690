import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Match } from '@reach/router'

import DividerIcon from '../../assets/icons/divider.svg'

const Logo = ({ icon: Icon, href }) => {
  return (
    <div className="c-navbar__logo u-flex u-items-center">
      <a href={href}>
        <Icon className="u-mt-xs" />
      </a>
      <DividerIcon className="u-mx-sm" />
      <b className="u-mt-2xs">
        <Match path="/updates/:id">
          {({ match }) =>
            match ? (
              <Link to="/updates/" className="u-text-white u-hover-none">
                Platform Updates
              </Link>
            ) : (
              <a href="/" className="u-text-white u-hover-none">
                Platform
              </a>
            )
          }
        </Match>
      </b>
    </div>
  )
}

Logo.propTypes = {
  icon: PropTypes.func,
  href: PropTypes.string,
}

export default Logo
