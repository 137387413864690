import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMedia from 'use-media'
import Image from './Image'

const MainUpdate = ({
  slug,
  title,
  author,
  date,
  image,
  index,
  eyeCatcher,
  wider,
}) => {
  const isWide = useMedia({ minWidth: 720 })
  const isSmall = useMedia({ maxWidth: 720 })
  return (
    <section className="o-container u-Mb-md md:u-Mb-xl">
      <div
        className={`u-flex ${isWide ? 'u-items-center' : ''}`}
        style={{ flexDirection: isSmall ? 'column-reverse' : '' }}
      >
        <div style={{ width: isSmall ? '100%' : wider ? '75%' : '55%' }}>
          <Image
            image={image}
            title={title}
            index={index}
            slug={slug}
            wider={wider}
          />
        </div>
        <div
          style={{
            width: isSmall ? '100%' : wider ? '50%' : '45%',
            marginLeft: isSmall ? '' : wider ? '3%' : '7%',
          }}
        >
          {eyeCatcher && (
            <h3 className="u-text-p5 u-text-livechat">
              <b>{eyeCatcher}</b>
            </h3>
          )}
          <Link
            to={`/updates/${slug}`}
            className={`u-hover-pointer u-block u-text-white u-text-p3 u-mb-md`}
          >
            {title}
          </Link>
          <p className={`u-text-p6 ${isSmall ? 'u-mb-md' : 'u-mb-0'}`}>
            {author && author[0]} on {date}
          </p>
        </div>
      </div>
    </section>
  )
}

MainUpdate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.array,
  date: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
  index: PropTypes.number,
  eyeCatcher: PropTypes.string,
  wider: PropTypes.bool,
}

export default MainUpdate
