import React from 'react'
import PropTypes from 'prop-types'

const Menu = ({ children }) => {
  return (
    <div className="c-navbar__menu u-bg-black">
      <ul className="c-navbar__list">{children}</ul>
    </div>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

export default Menu
