import React from 'react'
import { node } from 'prop-types'

const PromoBanner = ({ children }) => {
  return (
    <div className="u-text-center u-text-p7-bold u-py-xs u-px-md promo-banner">
      {children}
    </div>
  )
}

PromoBanner.propTypes = {
  children: node,
}

export default PromoBanner
