import React from 'react'
import PropTypes from 'prop-types'

const Benefits = ({ content, HD }) => {
  return (
    <section
      className={`o-container u-Mb-xl o-columns ${
        HD ? 'v--two' : 'v--three'
      } v--responsive xs: u-Px-md`}
    >
      {content?.map(({ icon: Icon, iconFill, label, description }, i) => {
        return (
          <div key={`${label}-${i}`} className={HD ? 'hd-benefit' : ''}>
            {Icon && (
              <div
                className="u-flex u-items-center"
                style={{ minHeight: '125px' }}
              >
                <Icon className={`u-Mb-xs u-mx-auto ${iconFill}`} />
              </div>
            )}
            <h5 className="u-text-p6 u-font-medium">{label}</h5>
            <h6 className="u-text-p6">{description}</h6>
          </div>
        )
      })}
    </section>
  )
}

Benefits.propTypes = {
  content: PropTypes.array,
  HD: PropTypes.bool,
}

export default Benefits
