import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

const MainHeadings = ({ title, subtitle, vertical }) => {
  const isWide = useMedia({ minWidth: 768 })

  return (
    <div className="o-container u-Pt-xl u-Mb-xs">
      <section
        className={`u-Mb-md u-flex u-flex-col ${
          vertical ? '' : 'md:u-items-end md:u-flex-row'
        }`}
      >
        <h1 className="u-text-p2 u-mb-xs md:u-mb-md u-w-full">{title}</h1>
        {subtitle && (
          <h2
            className={`u-text-p5 u-mb-0 u-w-full u-flex u-maxw-6 ${
              isWide ? 'u-justify-end' : 'u-justify-start'
            } 
            ${vertical ? '' : 'u-Mb-sm'}
            
            `}
          >
            <span>{subtitle}</span>
          </h2>
        )}
      </section>
    </div>
  )
}

MainHeadings.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  vertical: PropTypes.bool,
}

export default MainHeadings
