import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import DeveloperWebsiteOgImage from '../assets/images/developer-website-seo-image.png'

const SEO = ({
  description = 'LiveChat Platform – The building blocks of remarkable customer service experience.',
  defaultTitle = 'LiveChat Platform – Chat framework for innovative teams',
  title,
  image = DeveloperWebsiteOgImage,
  url,
  type,
  typeLabel,
  priceAmount,
  priceCurrency,
  schemaMarkup,
  path = '',
  children,
}) => {
  const imageUrl = `${process.env.GATSBY_SITE_URL}${image}`

  return (
    <Helmet defaultTitle={defaultTitle}>
      <meta property="ui-version" content={process.env.GATSBY_GIT_SHA} />

      <title itemProp="name" lang="en">
        {title}
      </title>

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content={description} />

      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={imageUrl} />

      <meta name="twitter:site" content="livechat" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:creator" content="livechat" />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />

      {priceAmount && <meta name="twitter:data1" content={priceAmount} />}
      {priceAmount && <meta name="twitter:label1" content="Price" />}

      {type && <meta name="twitter:data2" content={type} />}
      {type && <meta name="twitter:label2" content={typeLabel} />}

      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="LiveChat" />
      {priceAmount && <meta property="og:price:amount" content={priceAmount} />}
      {priceAmount && (
        <meta property="og:price:currency" content={priceCurrency} />
      )}
      <meta property="og:type" content="article" />

      {process.env.GATSBY_CANONICAL_URL && (
        <link
          rel="canonical"
          href={`${process.env.GATSBY_CANONICAL_URL}${path}`}
        />
      )}

      {schemaMarkup && (
        <script type="application/ld+json">{schemaMarkup}</script>
      )}

      {process.env.GATSBY_ENV !== 'prod' && (
        <meta name="robots" content="noindex" />
      )}

      {/* Piwik script */}

      {process.env.NODE_ENV === 'production' && (
        <script type="text/javascript">{`
    (function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="//livechat.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '${process.env.GATSBY_PIWIK_ID}');
    `}</script>
      )}

      {/* Piwik noscript elements */}

      {process.env.NODE_ENV === 'production' && (
        <noscript>{`
    <iframe
          src="//livechat.containers.piwik.pro/${
            process.env.GATSBY_PIWIK_ID
          }/noscript.html"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        />
    `}</noscript>
      )}

      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  defaultTitle: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  typeLabel: PropTypes.string,
  priceAmount: PropTypes.number,
  priceCurrency: PropTypes.string,
  schemaMarkup: PropTypes.string,
  path: PropTypes.string,
  children: PropTypes.node,
}

export default SEO
