import React from 'react'
import PropTypes from 'prop-types'
import RulesTitle from './RulesTitle'
import './rules.less'

const Rules = ({ title, subtitle, children }) => {
  return (
    <div className="u-P-2xs u-Pt-xl u-Pb-md md:u-p-0 md:u-Pb-xl">
      <RulesTitle title={title} subtitle={subtitle} />

      <section className="rules u-px-xs md:u-px-0">
        <article className="rules__content">{children}</article>
      </section>
    </div>
  )
}

Rules.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
}

export default Rules
