import React from 'react'
import { Link } from 'gatsby'
import { number, string } from 'prop-types'

import ArrowRight from '../../assets/icons/arrow-right.svg'

const Next = ({ currentPage, category, numPages, query }) => {
  const param = query ? `?query=${query}` : ''

  return (
    <li className="pagination__item pagination__next " aria-label="Next">
      {currentPage === numPages ? (
        <div className="edge">
          <span className="u-hidden md:u-inline pagination_edge">Next</span>
          <ArrowRight />
        </div>
      ) : (
        <Link to={`/updates/${category}/${currentPage + 1}${param}`}>
          <span className="u-hidden md:u-inline pagination_edge">Next</span>
          <ArrowRight />
        </Link>
      )}
    </li>
  )
}

Next.propTypes = {
  currentPage: number,
  category: string,
  numPages: number,
  query: string,
}

export default Next
