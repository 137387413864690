import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

import PlayIcon from '../assets/icons/play.svg'
import Image from './Image'

const Video = ({
  preview,
  src,
  title,
  description,
  smaller,
  goDown,
  sideView,
  onStart,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const isWide = useMedia({ minWidth: 1200 })
  const isSmall = useMedia({ maxWidth: 768 })
  const wrapperStyle = {
    width: smaller ? (isWide ? '70%' : '100%') : '100%',
    height: sideView ? (isSmall ? '50vw' : '300px') : '50vw',
    maxHeight: smaller ? '412px' : '571px',
    borderRadius: '16px',
  }

  const onVideoStart = () => {
    setShowVideo(true)
    onStart()
  }

  return (
    <section
      className={`${sideView ? '' : 'o-container-xl u-Mb-md'}`}
      style={{ marginBottom: goDown ? '-2%' : '' }}
    >
      <div
        className={`u-relative ${
          title
            ? 'u-flex xl:u-items-start xl:u-items-center u-flex-col-rev xl:u-flex-row'
            : ''
        }`}
      >
        {showVideo ? (
          <iframe
            title={preview}
            style={wrapperStyle}
            src={`${src}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <Fragment>
            <PlayIcon
              onClick={onVideoStart}
              className="u-absolute"
              style={{
                width: '20%',
                height: '20%',
                zIndex: 20,
                left: smaller ? (isWide ? '19%' : '40%') : '40%',
                top: smaller ? (isWide ? '40%' : '55%') : '40%',
                cursor: 'pointer',
              }}
            />
            <Image
              type={preview}
              alt="Video"
              wrapperStyles={wrapperStyle}
              imgStyles={{ objectFit: 'cover' }}
            />
          </Fragment>
        )}
        {title && (
          <div
            className="u-block md:u-flex u-items-center u-justify-between xl:u-block xl:u-ml-xl"
            style={{ width: isWide ? '50%' : '100%' }}
          >
            <h2 className="u-text-p3">{title}</h2>
            <h3
              className="u-text-p5"
              style={{ width: isWide ? '90%' : isSmall ? '100%' : '50%' }}
            >
              {description}
            </h3>
          </div>
        )}
      </div>
    </section>
  )
}

Video.propTypes = {
  src: PropTypes.string,
  preview: PropTypes.string,
  smaller: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  goDown: PropTypes.bool,
  sideView: PropTypes.bool,
  onStart: PropTypes.func,
}

export default Video
