import React from 'react'

import WebIcon from '../../assets/icons/web.svg'
import WindowsIcon from '../../assets/icons/windows.svg'
import MacOSIcon from '../../assets/icons/mac-os.svg'
import FinderIcon from '../../assets/icons/apple.svg'
import AndroidIcon from '../../assets/icons/android.svg'

const Apps = () => {
  return (
    <div className="u-flex u-flex-wrap u-Mb-xs">
      <p className="u-text-p7-bold u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
        Get LiveChat App
      </p>
      <p className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-web-app/`}
          className="c-link v--reset u-text-p8 u-flex u-items-center u-w-fit"
        >
          <WebIcon />
          <span className="u-ml-xs">Web Browser</span>
        </a>
      </p>
      <p className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-for-windows/`}
          className="c-link v--reset u-text-p8 u-flex u-items-center u-w-fit"
        >
          <WindowsIcon />
          <span className="u-ml-xs">Windows</span>
        </a>
      </p>
      <p className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-for-mac/`}
          className="c-link v--reset u-text-p8 u-flex u-items-center u-w-fit"
        >
          <MacOSIcon />
          <span className="u-ml-xs">macOS</span>
        </a>
      </p>
      <p className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs u-text-p8 u-flex u-items-center u-w-fit">
        <FinderIcon />
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-for-ipad/`}
          className="c-link v--reset u-text-p8 u-ml-xs"
        >
          iPad
        </a>
        &nbsp;|&nbsp;
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-for-iphone/`}
          className="c-link v--reset u-text-p8"
        >
          iPhone
        </a>
      </p>
      <p className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
        <a
          href={`${
            process.env.GATSBY_PRODUCT_WEBSITE_URL
          }/app/livechat-for-android/`}
          className="c-link v--reset u-text-p8 u-flex u-items-center u-w-fit"
        >
          <AndroidIcon />
          <span className="u-ml-xs">Android</span>
        </a>
      </p>
    </div>
  )
}

export default Apps
