import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

const Numbers = ({ items }) => {
  const isWide = useMedia({ minWidth: 1200 })
  return (
    <section
      className={`o-container u-Mb-md o-columns ${
        isWide ? 'v--four' : 'v--two'
      }`}
      style={{ justifyItems: 'center' }}
    >
      {items.map((el, i) => (
        <div key={`${el.title}-${i}`} style={{ width: 270 }}>
          <h4 className="u-text-p6 u-text-gray-900">
            <b>{el.title}</b>
          </h4>
          <p className="u-text-p6 u-text-gray-900">{el.description}</p>
        </div>
      ))}
    </section>
  )
}

Numbers.propTypes = {
  items: PropTypes.array,
}

export default Numbers
