import React from 'react'
import useMedia from 'use-media'
import PropTypes from 'prop-types'

const JoinDevProgramForm = ({ sourceId }) => {
  const isExtraWide = useMedia({ minWidth: 1150 })
  const sourceUrl = typeof window !== 'undefined' && window.location.href

  return (
    <form action={`${process.env.GATSBY_ACCOUNTS_URL}signup`} method="GET">
      <div
        className={`u-mx-auto u-flex u-flex-col sm:u-flex-row ${!isExtraWide &&
          'u-flex-wrap'}`}
      >
        <input
          type="email"
          name="email"
          className={`u-mb-xs sm:u-mb-0 ${isExtraWide && 'u-mr-xs'}`}
          placeholder="Enter your business email"
          required
        />
        <input type="hidden" name="source_id" value={sourceId} />
        <input type="hidden" name="source_url" value={sourceUrl} />
        <input type="hidden" name="source_type" value="website" />
        <input
          type="hidden"
          name="client_id"
          value={process.env.GATSBY_ACCOUNTS_CLIENTID}
        />
        <input
          type="hidden"
          name="redirect_uri"
          value={process.env.GATSBY_CONSOLE_URL}
        />

        <button
          type="submit"
          className={`c-btn v--signup u-text-p6-bold ${!isExtraWide &&
            'u-w-full u-mt-xs'}`}
        >
          Sign up free
        </button>
      </div>
    </form>
  )
}

JoinDevProgramForm.propTypes = {
  sourceId: PropTypes.string,
}

export default JoinDevProgramForm
