import React from 'react'
import PropTypes from 'prop-types'

const FAQ = ({ content }) => {
  return (
    <section className="o-container u-Mb-xl md:u-Mb-2xl">
      <h2 className="u-text-p3 u-mb-2xl">Frequently Asked Questions</h2>
      <div className="o-columns v--three">
        {content.map(el => {
          return (
            <div
              key={el.question}
              className="u-h-full u-flex u-flex-col u-justify-between"
            >
              <h3 className="u-text-p6 u-mb-xs u-font-bold">{el.question}</h3>
              <h4 className="u-text-p6">{el.answer}</h4>
            </div>
          )
        })}
      </div>
    </section>
  )
}

FAQ.propTypes = {
  content: PropTypes.array,
}

export default FAQ
