import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMedia from 'use-media'

import Image from './Image'

const Hero = ({
  name,
  position,
  image,
  alt,
  quotation,
  button,
  link,
  imageStyles,
}) => {
  const isWide = useMedia({ minWidth: 870 })
  return (
    <section className="o-container-xl u-Mb-md md:u-mb-0">
      <div className="o-feature">
        <div className="u-mb-xs">
          <Image type={image} alt={alt} style={imageStyles} />
        </div>
        <div>
          <h3 className="u-text-p3 u-text-quote u-maxw-6 u-mx-auto md:u-ml-0 u-text-center md:u-text-left">
            {quotation}
          </h3>
          <p className="u-text-p6 u-text-center md:u-text-left">
            <strong>{name}</strong>, {position}
          </p>
          {button && (
            <div
              className="u-Pt-xs o-columns v--two u-items-center"
              style={{
                height: '48px',
                gridGap: isWide ? '3rem 2rem' : '20px',
              }}
            >
              <a
                href={button.link}
                className="c-btn v--livechat u-text-white u-text-p6-bold"
              >
                {button.label}
              </a>
              {link && (
                <div
                  className={`u-items-center ${
                    !isWide ? 'u-justify-center' : ''
                  }`}
                  style={{ display: 'flex' }}
                >
                  <span className="u-Mr-xs">or</span>
                  {link.to ? (
                    <Link
                      to={link.to}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-link u-text-white u-text-p7"
                    >
                      {link.label}
                    </Link>
                  ) : (
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-link u-text-white u-text-p7"
                    >
                      {link.label}
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  bgImage: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  imageStyles: PropTypes.object,
  quotation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  button: PropTypes.object,
  link: PropTypes.object,
}

export default Hero
