import React from 'react'
import JoinDevProgramForm from '../../components/JoinDevProgramForm'
import './block.less'

const JoinDevProgramBlock = () => {
  return (
    <div className="u-bg-white u-rounded u-text-black u-flex u-flex-col u-justify-between u-P-sm lg:u-P-lg">
      <div className="u-Mb-xl">
        <h2 className="u-text-p2 ">
          Join Developer Program <span className="u-text-mark">now</span>
        </h2>
        <p className="u-text-p5 u-text-gray-200">
          "LiveChat Marketplace provides us with tens of new leads each month.”
        </p>
        <p>
          <b>Justyna Janowska</b>, UX Designer at KnowledgeBase.ai
        </p>
      </div>

      <div className="u-Mt-lg">
        <JoinDevProgramForm sourceId="join-developer-program" />
      </div>
    </div>
  )
}

export default JoinDevProgramBlock
