import React from 'react'
import PropTypes from 'prop-types'

import Image from '../Image.js'
import './style.less'

const Features = ({
  features,
  isWide,
  isSmall,
  selected,
  setSelected,
  hdStyle,
}) => {
  const descriptionHandler = (i, description) => {
    if (hdStyle && !isSmall) {
      if (selected === i) {
        return <p className="u-text-p7 u-text-gray-600">{description}</p>
      }
    } else {
      return <p className="u-text-p7 u-text-gray-600">{description}</p>
    }
  }

  return (
    <div className="c-tour__features">
      {features.map(({ title, description, image }, i) => {
        return (
          <div
            key={`${title}-${i}`}
            className={`c-tour__feature u-Mb-md ${
              isWide && selected === i
                ? `v--active ${hdStyle ? 'u-border-hd ' : 'u-border-livechat'}`
                : ''
            }`}
            onClick={() => setSelected(i)}
          >
            <div>
              <h4
                className={hdStyle ? 'u-text-p4 u-font-bold' : 'u-text-p5-bold'}
              >
                {title}
              </h4>
              {descriptionHandler(i, description)}
              {!isWide && (
                <Image
                  type={image}
                  className="c-tour__image u-rounded u-shadow"
                  wrapperStyles={{
                    width: '100%',
                    height: '300px',
                  }}
                />
              )}
            </div>
            {selected === i && <span class="t-c-tour-progressbar" />}
          </div>
        )
      })}
    </div>
  )
}

Features.propTypes = {
  features: PropTypes.array,
  isWide: PropTypes.bool,
  isSmall: PropTypes.bool,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
  inverse: PropTypes.bool,
  gray: PropTypes.bool,
  hdStyle: PropTypes.bool,
}

export default Features
