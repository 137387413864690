import React from 'react'

export const promotion = {
  infoBanner: {
    isActive: false,
    body: (
      <>
        <img
          src="/support-ukraine.png"
          alt="Support Ukraine"
          style={{ height: '22px', width: '22px', margin: '0 8px 3px 0' }}
        />
        <span>
          We support Ukraine. We’ll be happy to join forces with you.{' '}
        </span>
        <a href="/updates/support-ukraine/">Volunteer your tech skills.</a>
      </>
    ),
  },
  videoCourseBanner: {
    isActive: false,
    body: (
      <>
        <span>
          Available now{' '}
          <span role="img" aria-label="pointing_right">
            👉&nbsp;
          </span>
        </span>
        <a href="/building-apps-crash-course/">
          Free building apps crash course
        </a>
      </>
    ),
  },
}

export const hdIdeas = [
  {
    title: 'Integration with Google Calendar',
    description:
      'Creating a meeting in Google Calendar, adding relevant information to the ticket details, and sending an invitation to the recipient in the form of a ticket response with a link to the actual meeting directly in the conversation.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with Slack',
    description:
      'Setting up webhooks to connect to Slack, allowing the creation of HelpDesk tickets directly from Slack messages. This adds a convenient way to communicate with team members and managers.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with Messenger',
    description:
      'Enabling the creation of HelpDesk tickets directly from Messenger messages, providing a new channel for communication with coworkers and external recipients.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with WhatsApp',
    description:
      'Setting up webhooks to connect to WhatsApp, allowing the creation of HelpDesk tickets directly from messages from the platform. This adds a new communication channel for team collaboration and external communication with clients.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with WordPress',
    description:
      'Creating a contact form for WordPress and linking it to HelpDesk, allowing website visitors to submit a contact form that will generate a HelpDesk ticket. This will enable website visitors to easily and quickly contact companies.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with payment service',
    description:
      'Integrating popular payment services with HelpDesk(Paypal/Google Pay/Apple Pay/Stripe) and enabling users to create and send custom payment links in communication feed to customers.',
    features: ['HelpDesk Widgets'],
  },
  {
    title: 'Integration with CRM/e-commerce platform',
    description:
      'Using HelpDesk-SDK to check existing tickets in the CRM and edit data directly in the ticket details. Sharing information between HelpDesk and the selected CRM about the recipient and their requirements gathered during the relationship-building process.',
    features: ['HelpDesk Widgets'],
  },
]

export const lcIdeas = [
  {
    title: 'CRM integrations',
    description:
      'There are still many options to explore when it comes to the CRMs LiveChat can be integrated with. Check out our Marketplace and see if you can find some inspiration!',
    features: ['Sign in with LiveChat', 'Agent App Widgets'],
  },
  {
    title: 'Please hold on',
    description:
      'Send a custom message to the customer if the chat is idle for a certain period of time.',
    features: ['Sign in with LiveChat', 'Agent Chat API'],
  },
  {
    title: 'Chat Source Report',
    description:
      'Improve the reporting experience by automatically tagging chats from a certain source.',
    features: ['Sign in with LiveChat', 'Configuration API'],
  },
  {
    title: 'Simplified tag editing',
    description:
      'Replace existing tags with new tags in all the chats they were already added to.',
    features: ['Sign in with LiveChat', 'Configuration API'],
  },
  {
    title: 'Agent grading',
    description:
      'Allow admins to internally grade the performance of any agent, scoring their chats.',
    features: ['Sign in with LiveChat', 'Agent App Widgets'],
  },
  {
    title: 'Google Play integration',
    description: 'Reply to your app reviews directly from chats.',
    features: ['Sign in with LiveChat', 'Agent Chat API'],
  },
  {
    title: 'Transcripts made prettier',
    description:
      'Send neatly formatted transcripts to a selected channel automatically after each chat ends.',
    features: ['Sign in with LiveChat'],
  },
  {
    title: 'Kustomer integration',
    description: 'This app would integrate LiveChat with the Kustomer CRM.',
    features: ['Sign in with LiveChat', 'Agent App Widgets'],
  },
  {
    title: 'Team Capacity',
    description:
      'The app lets you check your team capacity. You can easily check how many Agents are chatting and how many are active.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Chat Bots',
    description: 'Create mini-chatbots in a dedicated editor.',
    features: [
      'Agent App Widgets',
      'Sign in with LiveChat',
      'Agent Chat API',
      'Configuration API',
    ],
  },
  {
    title: 'Tips',
    description:
      'Reward Agents with tips, so that they can spend the extra money on pizza.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
      'Chat Widget Moments',
      'Billing API',
    ],
  },
  {
    title: 'Agent Alert',
    description: 'Notify other Agents when facing problems on a chat.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Water Tracker',
    description: 'Sends reminders about drinking water.',
    features: ['Agent App Widgets'],
  },
  {
    title: 'MyFinance',
    description:
      'If you share expenses with other Agents, MyFinance can help you keep track of your finances.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'LC Exam',
    description:
      'Before Agents become LiveChat power users, they need to know it inside out. LC Exam helps to test what you already know and learn even more!',
    features: ['Agent App Widgets'],
  },
  {
    title: 'Tracking Number Checker',
    description: 'Shows delivery statuses from e-commerce platforms.',
    features: ['Agent App Widgets', 'Chat Widget Moments'],
  },
  {
    title: 'Break Time',
    description: 'Reminds Agents to take a break.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: "What's for lunch",
    description:
      'Check what other Agents have for lunch and find out how long you need to wait for yours.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Agent Heart Rate Monitor',
    description:
      'Informs the Visitor about the Agent state in pre-defined time intervals. Gives the Visitor an estimated waiting time.',
    features: ['Agent App Widgets', 'Chat Widget Moments'],
  },
  {
    title: 'Drive together',
    description:
      'Give your Agent colleagues a lift home and split the cost in an easy way.',
    features: ['Agent App Widgets'],
  },
  {
    title: 'Forwarding messages',
    description:
      'Forward the currently active chat to a group or department, and add a note.',
    features: ['Agent Chat API', 'Sign in with LiveChat', 'Agent App Widgets'],
  },
  {
    title: 'Pay in the chat',
    description: 'Use Moments for visitor payments right in the chat.',
    features: [
      'Sign in with LiveChat',
      'Agent App Widgets',
      'Chat Widget Moments',
    ],
  },
  {
    title: 'Google Drive Integration',
    description: 'Send files to the visitor without leaving the chat.',
    features: ['Agent App Widgets'],
  },
  {
    title: 'Storing chats in Google Analytics',
    description:
      'Connect the data from LiveChat with Google Analytics capabilities.',
    features: ['LiveChat REST API', 'Webhooks'],
  },
  {
    title: 'Become the master of tickets',
    description:
      'Display advanced ticket reports, customized charts and list unsolved tickets.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Introduce customer scoring',
    description:
      'Let agents assign scores to the visitors. It could work as a lead scoring.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Display enhanced Customer Details',
    description:
      'Provide agents with more information about the customers they chat with. The information could be pulled from public data APIs.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Compete with your teammates',
    description:
      'A simple game for agents that keep their reports tight. Agents can compete in different categories: agent of the day, most cases solved, 90% of chats tagged, etc.',
    features: [
      'LiveChat REST API',
      'Sign in with LiveChat',
      'Agent App Widgets',
    ],
  },
  {
    title: 'Have currency converter at hand',
    description: 'Display a simple currency converter in Agent App Widgets.',
    features: ['Agent App Widgets'],
  },
  {
    title: "Remind about visitor's name day",
    description: "Show information about visitior's name day.",
    features: ['Agent App Widgets'],
  },
  {
    title: 'In-chat easy-access cloud storage',
    description:
      'Allows the Agent to easily upload and use cloud storage files from within the chat.',
    features: ['Agent App Widgets', 'Sign in with LiveChat'],
  },
  {
    title: 'Easy requests for help to other teams',
    description:
      "Enables an alert button for notifying other teams, e.g. the 2nd line support, with customizable output through API, such as Slack notifications. Allows the Agents to quickly request assistance with resolving the client's problem.",
    features: ['Agent App Widgets', 'Sign in with LiveChat'],
  },
  {
    title: 'Preset of Canned Responses',
    description:
      'A simple server-side app that installs a predefined presets of Canned Responses. With one click one can import a industry-specific set.',
    features: ['LiveChat REST API', 'Sign in with LiveChat'],
  },
  {
    title: 'UTM Builder for links',
    description: 'A widget that helps to create UTM - enabled links.',
    features: ['Agent App Widgets'],
  },
  {
    title: 'Assign custom labels to customers',
    description:
      'Categorize your customers with custom labels to recognize them easier during ongoing chats.',
    features: [
      'Agent App Widgets',
      'Sign in with LiveChat',
      'App Authorization',
    ],
  },
  {
    title: 'Kudos cards sender',
    description:
      'Express your gratitude to a team member by sending them a kudos card.',
    features: [
      'Agent App Widgets',
      'Sign in with LiveChat',
      'App Authorization',
    ],
  },
  {
    title: 'Measure the employees satisfaction score',
    description:
      'Allow agents to fill satisfaction surveys at the end of their shifts. Receive a report on the daily work quality based on your team’s feelings.',
    features: [
      'Agent App Widgets',
      'Sign in with LiveChat',
      'App Authorization',
    ],
  },
  {
    title: 'Guess the scope - permission game for Agents',
    description:
      'A game to test your knowledge about LiveChat API scopes required for accessing different application areas.',
    features: [
      'Agent App Widgets',
      'Sign in with LiveChat',
      'App Authorization',
    ],
  },
  {
    title: 'Closing chats from anonymous proxies',
    description:
      'Automatically close chats opened by customers browsing from anonymous proxies.',
    features: ['App Authorization', 'Chat Webhooks'],
  },
  {
    title: 'Automatic chat tagging',
    description:
      'Create automation for tagging based on a custom solution analyzing the chat content. You can use triggers like canned responses or keywords sent in the chat.',
    features: ['Agent App Widgets', 'Chat Webhooks', 'App Authorization'],
  },
  {
    title: 'Game for customers in the queue',
    description:
      'Create a simple game for customers waiting in the queue and not lose any lead by increasing their engagement with the chat.',
    features: ['Chat Widget Moments', 'Chat Webhooks', 'App Authorization'],
  },
]
