import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'

export const RulesTitle = ({ title, subtitle }) => {
  return (
    <section className="u-bg-black-3 u-items-center u-overflow-hidden u-justify-center u-Mb-md u-px-xs md:u-px-0">
      <div className="rules-title u-relative u-flex u-flex-col u-justify-end u-py-xl u-px-0">
        <h1 className="u-text-p3">{title}</h1>
        <span className="u-text-6">{subtitle}</span>

        <Image
          type="twistedLine"
          wrapperStyles={{
            position: 'absolute',
            left: -660,
            bottom: -160,
            width: 'calc(500px + 10%)',
            height: '100%',
          }}
        />
      </div>
    </section>
  )
}

RulesTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default RulesTitle
