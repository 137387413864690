import React from 'react'
import PropTypes from 'prop-types'
import Slice from './Slice'

const Products = ({ items, label, columns = 3 }) => {
  const getFlexClass = () => {
    const classes = {
      2: 'v--two',
      3: 'v--three',
      4: 'v--four',
    }
    return classes[columns] || 'v--three'
  }
  return (
    <div className="o-container">
      <h3 className="u-text-p5-bold u-Mb-md u-text-livechat">
        {label || 'Products'}
      </h3>
      <div className={`o-columns ${getFlexClass()} v--responsive`}>
        {items.map((el, i) => (
          <Slice
            key={`${el.name}-${i}`}
            name={el.name}
            description={el.description}
            link={el.to}
            cta={el.cta}
          />
        ))}
      </div>
    </div>
  )
}

Products.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  columns: PropTypes.number,
}

export default Products
