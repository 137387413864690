import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'

const Gallery = ({ isWide, image, alt, imgStyles }) => {
  return (
    <div className="c-tour__gallery">
      {isWide && (
        <div key={image} className="u-anim-fade-in-right">
          <Image
            type={image}
            alt={alt}
            imgStyles={imgStyles}
            className="u-rounded u-shadow"
            wrapperStyles={{
              height: '536px',
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  )
}

Gallery.propTypes = {
  isWide: PropTypes.bool,
  image: PropTypes.string,
  alt: PropTypes.string,
  imgStyles: PropTypes.object,
}

export default Gallery
