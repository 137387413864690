import React from 'react'
import { Link } from 'gatsby'

const Pages = ({ currentPage, numPages, category, query }) => {
  const param = query ? `?query=${query}` : ''

  const pagesArray = [...Array(numPages + 1).keys()]
    .slice(1)
    .map(page =>
      page === 1 || page === numPages || Math.abs(page - currentPage) < 2
        ? page
        : -1
    )
    .filter((page, index, array) => page !== array[index - 1])

  return pagesArray.map((page, index) => {
    if (page === -1) {
      return <span key={`pagination-${category}-${index}`}>...</span>
    }

    return (
      <li className="pagination__item" key={`pagination-${category}-${index}`}>
        <Link
          to={
            page === 1
              ? `/updates/${category}/${param}`
              : `/updates/${category}/${page}/${param}`
          }
          className={currentPage === page ? 'v--active' : ''}
        >
          {page}
        </Link>
      </li>
    )
  })
}

export default Pages
