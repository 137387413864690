import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

import './style.less'

const Heading = ({ title, subtitle }) => {
  const isWide = useMedia({ minWidth: 768 })

  return (
    <section className="o-container u-flex u-items-center u-justify-between u-flex-col md:u-flex-row">
      <h1 className="u-text-p2 u-mb-xs md:u-mb-0 u-w-full">{title}</h1>
      <h2
        className={`${
          isWide
            ? 'u-text-p5 u-mb-0 u-justify-end'
            : 'u-text-p6 u-mb-md u-justify-start'
        } u-w-full u-flex`}
      >
        <span className="u-w-fit subtitle">{subtitle}</span>
      </h2>
    </section>
  )
}

Heading.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Heading
