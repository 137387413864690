import { canUseWindow } from './index'

export default class Analytics {
  static track({ category, action, label, value, license }) {
    if (canUseWindow) {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        event: 'generic',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
        license,
      })
    }
  }
}
