import React from 'react'
import useMedia from 'use-media'

import TwitterIcon from '../../../assets/icons/twitter.svg'
import LinkedinIcon from '../../../assets/icons/linkedin.svg'
import FacebookIcon from '../../../assets/icons/facebook.svg'
import YouTubeIcon from '../../../assets/icons/youtube.svg'
import GitHubIcon from '../../../assets/icons/github.svg'
import DiscordIcon from '../../../assets/icons/discord.svg'
import LiveChatIcon from '../../../assets/icons/livechat-icon.svg'
import HelpDeskIcon from '../../../assets/icons/helpdesk-icon.svg'
import DividerIcon from '../../../assets/icons/divider.svg'
import './footer.less'

const solutionsTab = [
  { label: 'Programmable Chat', to: 'programmable-chat/' },
  { label: 'Open Chat Widget', to: 'open-chat-widget/' },
  { label: 'Open Agent App', to: 'open-agent-app/' },
  {
    label: 'Data & Reporting APIs',
    to: 'data-and-reporting/',
  },
  { label: 'Omnichannel APIs', to: 'omnichannel/' },
  { label: 'Private Apps', to: 'private-apps/' },
]

const developerProgramTab = [
  {
    label: 'Program Overview',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}developer-program/`,
  },
  {
    label: 'Program Offer',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}developer-program/offer/`,
  },
  {
    label: 'Why build with us?',
    to: `${
      process.env.GATSBY_DEVELOPER_WEBSITE_URL
    }developer-program/why-build-with-livechat/`,
  },
  {
    label: 'App Ideas',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}app-ideas/`,
  },
  {
    label: 'Explore Marketplace',
    link: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}marketplace/`,
  },
]

const documentationTab = [
  {
    label: 'Getting Started',
    to: 'getting-started',
  },
  {
    label: 'Authorization',
    to: 'authorization',
  },
  {
    label: 'Messaging',
    to: 'messaging',
  },
  {
    label: 'Agent App',
    to: 'extending-agent-app',
  },
  {
    label: 'Chat Widget',
    to: 'extending-chat-widget',
  },
  {
    label: 'Management',
    to: 'management',
  },
  {
    label: 'Data & Reporting',
    to: 'data-reporting',
  },
  {
    label: 'Monetization',
    to: 'monetization',
  },
]

const supportTab = [
  {
    label: 'Help Center',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}support/`,
  },
  {
    label: 'Updates',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}updates/`,
  },
  {
    label: 'API Status',
    to: process.env.GATSBY_STATUS_WEBSITE_URL,
  },
  {
    label: 'Developer Terms',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}developer-terms/`,
  },
  {
    label: 'Marketplace Guidelines',
    to: `${process.env.GATSBY_DEVELOPER_WEBSITE_URL}marketplace-guidelines/`,
  },
  {
    label: 'Discord',
    to: `${process.env.GATSBY_DISCORD_LINK}`,
  },
]

const companyTab = [
  {
    label: 'About',
    to: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}about/`,
  },
  {
    label: 'Contact',
    to: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}contact/`,
  },
  {
    label: 'Team',
    to: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}team/`,
  },
  {
    label: 'Careers',
    to: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}careers/`,
  },
  {
    label: 'Investor Relations',
    to: process.env.GATSBY_INVESTOR_WEBSITE_URL,
  },
  {
    label: 'Press',
    to: `${process.env.GATSBY_PRODUCT_WEBSITE_URL}press/`,
  },
  {
    label: 'Partner Program',
    to: process.env.GATSBY_PARTNER_WEBSITE_URL,
  },
]

const Footer = () => {
  const isSmall = useMedia({ maxWidth: 400 })

  return (
    <footer className="footer-bg u-Pt-md">
      <div>
        <div className="o-container">
          <nav className="u-flex u-flex-wrap u-Mb-sm">
            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs logos-wrapper">
              <div>
                <LiveChatIcon />
                <HelpDeskIcon />

                <DividerIcon className="u-mx-sm" />

                <b>Platform</b>
              </div>
            </div>
            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs u-text-gray-900">
                Solutions
              </p>
              <ul className="u-p-0 u-m-0 list">
                {solutionsTab.map((item, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.GATSBY_DEVELOPER_WEBSITE_URL +
                        item.to}`}
                      className="u-text-p7 c-link v--reset"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs u-text-gray-900">
                Developer Program
              </p>
              <ul className="u-p-0 u-m-0 list">
                {developerProgramTab.map((item, index) => (
                  <li key={index}>
                    <a href={item.to} className="u-text-p7 c-link v--reset">
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs u-text-gray-900">
                Documentation
              </p>
              <ul className="u-p-0 u-m-0 list">
                {documentationTab.map((item, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.GATSBY_DOCS_URL + item.to}`}
                      className="u-text-p7 c-link v--reset"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs u-text-gray-900">Support</p>
              <ul className="u-p-0 u-m-0 list">
                {supportTab.map((item, index) => (
                  <li key={index}>
                    <a href={item.to} className="u-text-p7 c-link v--reset">
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs u-text-gray-900">Company</p>
              <ul className="u-p-0 u-m-0 list">
                {companyTab.map((item, index) => (
                  <li key={index}>
                    <a href={item.to} className="u-text-p7 c-link v--reset">
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>

          <div className="u-flex u-justify-between u-flex-col md:u-flex-row u-Mb-xs">
            <div className="u-flex u-flex-wrap u-items-center u-Mb-xs">
              <p
                className={`u-text-p7-bold u-mb-0 ${
                  isSmall ? 'u-mr-2xs' : 'u-mr-2xl'
                }`}
              >
                Start building apps
              </p>
              <a
                href={process.env.GATSBY_CONSOLE_URL}
                className="console-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Console
              </a>
            </div>

            <div className="u-flex u-items-center u-Mb-xs u-justify-between u-w-full u-maxw-4">
              <a
                href={process.env.GATSBY_PRODUCT_TWITTER_URL}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon fill="#767680" />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_LINKEDIN_URL}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_FACEBOOK_URL}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_YOUTUBE_URL}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_GITHUB_URL}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHubIcon />
              </a>

              <a
                href={process.env.GATSBY_DISCORD_LINK}
                className="logo-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="o-container u-Py-xs u-flex u-justify-between disclaimer">
        <p className="u-text-p9 u-Mb-xs u-text-white">
          Copyright © {new Date().getFullYear()} LiveChat, Inc. All rights
          reserved. We use Cookies.{' '}
          <a
            href={`${
              process.env.GATSBY_PRODUCT_WEBSITE_URL
            }legal/cookies-policy`}
            className="u-text-p9-bold u-text-white"
          >
            Click to learn more →
          </a>
        </p>
        <p className="u-text-p9 u-text-white u-w-fill">
          LiveChat – customer service software for business: live chat software
          to increase sales · Free live chat trial · Comes with built-in help
          desk software.
        </p>
      </div>
    </footer>
  )
}

export default Footer
