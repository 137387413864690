import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useMedia from 'use-media'

import NextIcon from '../../assets/icons/next.svg'
import Analytics from '../../utils/analytics'

const Item = ({ label, link, to, dropdownList, setDropdown }) => {
  const isWide = useMedia({ minWidth: 1024 })
  const buttonAction = 'Button Click'

  const classes = `c-navbar__link u-text-white ${
    dropdownList ? 'v--has-dropdown' : ''
  } ${!link && !to ? 'u-hover-default' : ''}`

  const linkStyle = `${classes} ${
    isWide ? '' : 'u-flex u-justify-between u-items-center u-w-full'
  }`

  const isMobileDropdown = !isWide && dropdownList

  const onClick = () => {
    if (isMobileDropdown) {
      setDropdown({ label, list: dropdownList })
    }

    Analytics.track({
      category: label,
      action: buttonAction,
      label: label,
    })
  }

  const track = label => {
    Analytics.track({
      category: label,
      action: buttonAction,
      label: label,
    })
  }

  return (
    <li className="c-navbar__item" onClick={onClick}>
      {to ? (
        <Link
          to={to}
          className={linkStyle}
          data-controller="track"
          data-track-category={label}
          data-track-action={buttonAction}
          data-track-label={label}
          data-action="track#send"
        >
          {label}
          {isMobileDropdown && <NextIcon />}
        </Link>
      ) : (
        <a
          href={link}
          className={linkStyle}
          data-controller="track"
          data-track-category={label}
          data-track-action={buttonAction}
          data-track-label={label}
          data-action="track#send"
          onClick={() => track(label)}
        >
          {label}
          {isMobileDropdown && <NextIcon />}
        </a>
      )}

      {dropdownList && (
        <ul className="c-navbar__sublist">
          {dropdownList.map(el => (
            <li key={el.label}>
              {el.to ? (
                <Link
                  to={el.to}
                  className="c-navbar__sublink"
                  data-controller="track"
                  data-track-category={el.label}
                  data-track-action={buttonAction}
                  data-track-label={el.label}
                  data-action="track#send"
                  onClick={() => track(el.label)}
                >
                  {el.label}
                </Link>
              ) : (
                <a
                  href={el.link}
                  className="c-navbar__sublink"
                  target={el?.target || '_blank'}
                  rel="noopener noreferrer"
                  data-controller="track"
                  data-track-category={el.label}
                  data-track-action={buttonAction}
                  data-track-label={el.label}
                  data-action="track#send"
                  onClick={() => track(el.label)}
                >
                  {el.label}
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

Item.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  to: PropTypes.string,
  dropdownList: PropTypes.array,
  setDropdown: PropTypes.func,
}

export default Item
