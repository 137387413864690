import React, { useState } from 'react'
import { Dropdown, DropdownList } from '@livechat/design-system'
import useMedia from 'use-media'
import { useAuth } from '../../../hooks/useAuth'
import AccountCircleIcon from '../../../assets/icons/account-circle.svg'
import './profile.less'

const Profile = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { authorize, isAuthorized, logout, user } = useAuth()
  const isWide = useMedia({ minWidth: 1024 })

  const { name, avatar_url, email } = user

  const handleClose = () => setIsVisible(false)
  const handleTriggerClick = () => setIsVisible(!isVisible)
  const handleAuthorization = () => {
    if (isAuthorized) {
      logout()
    } else {
      authorize()
    }
    handleClose()
  }

  const listItems = [
    {
      itemId: 1,
      content: (
        <div className="profile-item-wrapper">
          {!avatar_url || avatar_url === '' ? (
            <AccountCircleIcon />
          ) : (
            <img src={avatar_url} alt="avatar" className="profile-photo" />
          )}
          <div>
            <div>{name}</div>
            <div>{email}</div>
          </div>
        </div>
      ),
      divider: true,
    },
    {
      itemId: 2,
      content: (
        <a
          href={process.env.GATSBY_CONSOLE_URL}
          className="dropdown-item link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          Go to Console
        </a>
      ),
    },
    {
      itemId: 3,
      content: (
        <a
          href={process.env.GATSBY_PRODUCT_WEBSITE_URL}
          className="dropdown-item link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        >
          Go to LiveChat App
        </a>
      ),
      divider: true,
    },
    {
      itemId: 4,
      content: (
        <div className={isWide ? 'dropdown-item' : 'profile-login-btn'}>
          {isAuthorized ? `Log out` : `Log in`}
        </div>
      ),
      onItemSelect: handleAuthorization,
    },
  ]

  if (!isWide) {
    return (
      <div className="u-flex u-justify-between u-flex-wrap bacc_about-sec-gap">
        <div>{listItems[0].content}</div>
        <div onClick={handleAuthorization}>{listItems[3].content}</div>
      </div>
    )
  }

  if (!name && !email) {
    listItems.shift()
  }

  return (
    <div>
      <Dropdown
        isVisible={isVisible}
        placement="bottom-end"
        onClose={() => setIsVisible(false)}
        triggerRenderer={({ ref }) => (
          <div
            ref={ref}
            onClick={() => setIsVisible(!isVisible)}
            className="icon-wrapper"
          >
            {!avatar_url || avatar_url === '' ? (
              <AccountCircleIcon />
            ) : (
              <img
                src={avatar_url}
                alt="avatar"
                className="profile-photo"
                onClick={handleTriggerClick}
              />
            )}
          </div>
        )}
      >
        <DropdownList items={listItems} className="dropdown-list" />
      </Dropdown>
    </div>
  )
}

export default Profile
