import React from 'react'
import PropTypes from 'prop-types'
import Slice from './Slice'

const Solutions = ({ title, items }) => {
  return (
    <div className="o-container u-Mb-xs md:u-Mb-xl">
      <section className="u-Mb-sm">
        <h1 className="u-text-p2 u-mb-0">{title}</h1>
      </section>
      <h3 className="u-text-p5-bold u-Mb-md u-text-livechat u-text-livechat">
        Solutions
      </h3>
      <div className="o-columns v--three v--responsive v--sparse">
        {items.map((el, i) => (
          <Slice
            withButton={el.withButton}
            key={`${el.name}-${i}`}
            name={el.name}
            description={el.description}
            link={el.to}
            externalLink={el.externalLink}
            cta={el.label}
          />
        ))}
      </div>
    </div>
  )
}

Solutions.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.array,
}

export default Solutions
