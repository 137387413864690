import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import Image from './Image'

export const Action = ({ image, title, button, link, subtitle }) => {
  const isWide = useMedia({ minWidth: 870 })
  const isSmall = useMedia({ maxWidth: 450 })

  const getImage = image => {
    if (typeof image === 'string') {
      return (
        <Image
          type={image}
          wrapperStyles={{ width: '100%', height: '100%' }}
          className={`${!isWide ? 'u-Mb-md' : ''}`}
        />
      )
    }

    return image
  }
  return (
    <section
      className="o-container u-items-center u-justify-center u-Py-md"
      style={{
        display: 'flex',
        flexDirection: isWide ? 'row' : 'column',
      }}
    >
      <div
        className="u-relative u-Mr-sm "
        style={{
          width: isWide ? '40%' : '100%',
        }}
      >
        <Image
          type="twistedLine"
          wrapperStyles={{
            position: 'absolute',
            left: -350,
            bottom: -100,
            width: 'calc(500px + 10%)',
            height: '100%',
          }}
        />
        {getImage(image)}
      </div>
      <div style={{ width: isWide ? '50%' : isSmall ? '100%' : '70%' }}>
        <h3 className={`u-text-p3 ${subtitle ? 'u-mb-xs' : 'u-Mb-xs'}`}>
          {title}
        </h3>
        {subtitle && <h4 className="u-text-p6 u-Mb-xs">{subtitle}</h4>}
        <div
          className="o-columns v--two u-items-center"
          style={{
            height: '48px',
            gridGap: isWide ? '3rem 2rem' : '20px',
          }}
        >
          <a
            href={button.link}
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            {button.label}
          </a>
          {link && (
            <div
              className={`u-items-center ${!isWide ? 'u-justify-center' : ''}`}
              style={{ display: 'flex' }}
            >
              <span className="u-Mr-xs">or</span>
              <a
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
                className="c-link u-text-white u-text-p7"
              >
                {link.label}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

Action.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  description: PropTypes.string,
  button: PropTypes.object,
  image: PropTypes.node,
  link: PropTypes.object,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Action
