import React from 'react'
import useMedia from 'use-media'

import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import FacebookIcon from '../../assets/icons/facebook.svg'
import YouTubeIcon from '../../assets/icons/youtube.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'
import ProductHuntIcon from '../../assets/icons/producthunt.svg'
import DribbbleIcon from '../../assets/icons/dribbble.svg'
import GitHubIcon from '../../assets/icons/github.svg'
import Analytics from '../../utils/analytics'
import './footer.less'

import Apps from './Apps'

const Footer = () => {
  const isSmall = useMedia({ maxWidth: 400 })
  const buttonAction = 'Button Click'

  const track = label => {
    Analytics.track({
      category: label,
      action: buttonAction,
      label: label,
    })
  }

  return (
    <footer className="u-bg-black u-Pt-md">
      <div>
        <div className="o-container">
          <Apps />
          <nav className="u-flex u-flex-wrap u-Mb-sm">
            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Product</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}pricing/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Pricing
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}benefits/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    LiveChat Benefits
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}tour/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Tour
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}features/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Features
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}use-cases/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Use Cases
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}app/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    App
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }marketplace/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Marketplace
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}updates/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Updates
                  </a>
                </li>
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Customers</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}customers/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Customers
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }customers/customer-stories/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Case Studies
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }customers/testimonials/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Testimonials
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }customers/industries/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Industries
                  </a>
                </li>
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Resources</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}success/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Success by LiveChat
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }newsletter/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Newsletter
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }resources/infographics/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Infographics
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }resources/reports/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Reports
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}benchmark/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Benchmark
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }typing-speed-test/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Typing Speed Test
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }utm-builder/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    UTM Builder
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }customer-service-report/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Customer Service Report
                  </a>
                </li>
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Support</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}help/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Help Center
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}webinars/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Webinars
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }marketplace/experts/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Experts Marketplace
                  </a>
                </li>

                <li>
                  <a
                    href={process.env.GATSBY_DEVELOPER_WEBSITE_URL}
                    className="u-text-p7 c-link v--reset"
                  >
                    API &amp; Developers
                  </a>
                </li>

                <li>
                  <a
                    href={process.env.GATSBY_STATUS_WEBSITE_URL}
                    className="u-text-p7 c-link v--reset"
                  >
                    System Status
                  </a>
                </li>
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Company</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}contact/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Contact
                  </a>
                </li>

                <li>
                  <a
                    href={process.env.GATSBY_INVESTOR_WEBSITE_URL}
                    className="u-text-p7 c-link v--reset"
                  >
                    Investor Relations
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}team/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Team
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}about/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    About
                  </a>
                </li>

                <li>
                  <a
                    href={process.env.GATSBY_PARTNER_WEBSITE_URL}
                    className="u-text-p7 c-link v--reset"
                  >
                    Partner Program
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}press/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Press
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.GATSBY_PRODUCT_WEBSITE_URL}careers/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>

            <div className="u-flex-1 u-basis-6 sm:u-basis-4 lg:u-basis-2 u-Mb-xs">
              <p className="u-text-p7-bold u-Mb-2xs">Legal</p>
              <ul className="u-p-0 u-m-0">
                <li>
                  <a
                    href={`${
                      process.env.GATSBY_DEVELOPER_WEBSITE_URL
                    }developer-terms/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Developer Terms
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }legal/terms-of-use/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Terms of Use
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }legal/privacy-policy/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Privacy Policy
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }legal/security/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    Security
                  </a>
                </li>

                <li>
                  <a
                    href={`${
                      process.env.GATSBY_PRODUCT_WEBSITE_URL
                    }legal/gdpr-faq/`}
                    className="u-text-p7 c-link v--reset"
                  >
                    GDPR
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <div className="u-flex u-justify-between u-flex-col md:u-flex-row u-Mb-xs">
            <div className="u-flex u-items-center u-Mb-xs">
              <p
                className={`u-text-p7-bold u-mb-0 ${
                  isSmall ? 'u-mr-2xs' : 'u-mr-2xl'
                }`}
              >
                Start your free trial
              </p>
              <a
                href={`${
                  process.env.GATSBY_PRODUCT_WEBSITE_URL
                }signup?source_id=footer_cta&amp;source_url=${
                  process.env.GATSBY_DEVELOPER_WEBSITE_URL
                }&amp;source_type=website`}
                className="c-btn v--signup v--sm"
                data-controller="track"
                data-track-category="Signup"
                data-track-action="Signup Redirect"
                data-action="track#send"
                onClick={() => track('Signup')}
              >
                Sign up free
              </a>
            </div>

            <div className="u-flex u-items-center u-Mb-xs u-justify-between u-w-full u-maxw-4">
              <a
                href={process.env.GATSBY_PRODUCT_TWITTER_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <TwitterIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_LINKEDIN_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <LinkedinIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_FACEBOOK_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <FacebookIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_YOUTUBE_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <YouTubeIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_INSTAGRAM_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <InstagramIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_PRODUCTHUNT_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <ProductHuntIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_DRIBBBLE_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <DribbbleIcon />
              </a>

              <a
                href={process.env.GATSBY_PRODUCT_GITHUB_URL}
                className="u-text-gray-100 hover:u-text-gray-300"
              >
                <GitHubIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="u-w-full u-border-t footer-border" />
      <div className="o-container u-Py-xs">
        <p className="u-text-p9 u-Mb-xs u-text-white">
          Copyright © {new Date().getFullYear()} LiveChat, Inc. All rights
          reserved
        </p>
        <p className="u-text-p9 u-text-white">
          Hi! We are glad to have you here! Before you start visiting our Site,
          please note that for the best user experience, we use Cookies. By
          continuing to browse our Site, you consent to the collection, use, and
          storage of cookies on your device for us and our partners. You can
          revoke your consent any time in your device browsing settings.{' '}
          <a
            href={`${
              process.env.GATSBY_PRODUCT_WEBSITE_URL
            }legal/cookies-policy`}
            className="u-text-p9-bold u-text-white"
          >
            Click “Cookies Policy”
          </a>{' '}
          to check how you can control them through your device.
        </p>
      </div>
    </footer>
  )
}

export default Footer
