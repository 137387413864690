import React from 'react'
import PropTypes from 'prop-types'

import Analytics from '../../utils/analytics'
import Image from '../Image'
import Heading from './Heading'
import Features from './Features'

const Overview = ({ title, subtitle, image, features, button, line }) => {
  const getPosition = () => {
    if (line.position === 'right') {
      return { right: '-10%' }
    }
    if (line.position === 'left') {
      return { right: '70%' }
    }
    return {}
  }

  const buttonAction = 'Button Click'

  const track = label => {
    Analytics.track({
      category: label,
      action: buttonAction,
      label: label,
    })
  }

  return (
    <div className="u-Pt-xl u-overflow-hidden">
      <Heading title={title} subtitle={subtitle} />
      <section className="o-container">
        <div className="u-relative">
          <Image
            type={image.name}
            alt="Open Chat Widget"
            wrapperStyles={{
              width: '100%',
              height: '50vw',
              maxHeight: `${image.maxHeight || 560}px`,
              borderRadius: '16px',
              zIndex: 15,
              marginBottom: image.goDown ? '-8%' : '',
            }}
            imgStyles={{ objectFit: 'contain' }}
          />
          {line && (
            <Image
              type="twistedLine2"
              alt="Line"
              wrapperStyles={{
                transform: `rotate(${line.rotate || 10}deg)`,
                overflow: 'hidden',
                top: -70,
                ...getPosition(),
                position: 'absolute',
                width: '50%',
              }}
              imgStyles={{ objectFit: 'fill' }}
            />
          )}
        </div>
      </section>
      <div className="u-bg-black2 u-Pt-xl">
        <section className="o-container">
          <h2 className="u-text-livechat u-text-p5-bold u-Mb-xs">
            Top features
          </h2>
        </section>
        <Features features={features} />
        <section className="o-container u-flex u-justify-center">
          <a
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn v--livechat u-text-white u-Mb-xl"
            data-controller="track"
            data-track-category={button.label}
            data-track-action={buttonAction}
            data-track-label={button.label}
            data-action="track#send"
            onClick={() => track(button.label)}
          >
            {button.label}
          </a>
        </section>
      </div>
    </div>
  )
}

Overview.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.object,
  features: PropTypes.array,
  link: PropTypes.string,
  button: PropTypes.object,
  line: PropTypes.object,
}

export default Overview
