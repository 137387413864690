import React from 'react'
import { string, object, oneOfType, bool, node } from 'prop-types'
import { Link } from 'gatsby'
import { useMedia } from 'use-media'

import Analytics from '../utils/analytics'
import Image from './Image'

const Feature = ({
  title,
  description,
  image,
  imgScale,
  imgAlt,
  component,
  button,
  inverse = false,
  link,
  goTop,
  smallMarginBottom,
  textColor = 'u-text-gray-800',
  imgObjectFit = 'cover',
  wrapperStyles,
  imgStyle,
  children,
}) => {
  const isMobile = useMedia({ maxWidth: 480 })
  const buttonAction = 'Button Click'

  const track = label => {
    Analytics.track({
      category: label,
      action: buttonAction,
      label: label,
    })
  }

  return (
    <section
      className={`o-container ${
        smallMarginBottom ? 'u-Mb-md' : 'u-Mb-xl xs:u-Mb-2xl'
      } o-feature ${inverse ? 'v--inverse' : ''}`}
    >
      <div>
        {image ? (
          <Image
            type={image}
            alt={imgAlt}
            wrapperStyles={{
              width: '100%',
              maxHeight: imgScale && isMobile ? '70vw' : '40vw',
              marginTop: goTop ? '-25%' : '',
              ...wrapperStyles,
            }}
            imgStyle={{ objectFit: imgObjectFit, ...imgStyle }}
          />
        ) : (
          component
        )}
      </div>
      <div>
        <h3 className={`u-text-p3 ${smallMarginBottom ? 'u-Mt-xl' : ''}`}>
          {title}
        </h3>
        <p className={`u-text-p6 u-mb-2xl ${textColor}`}>{description}</p>
        {button?.link && (
          <a
            href={button.link}
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn v--livechat u-text-white u-text-p6-bold"
            data-controller="track"
            data-track-category={button.label}
            data-track-action={buttonAction}
            data-track-label={button.label}
            data-action="track#send"
            onClick={() => track(button.label)}
          >
            {button.label}
          </a>
        )}
        {button?.to && (
          <Link
            to={button.to}
            className="c-btn v--livechat u-text-white u-text-p6-bold"
            data-controller="track"
            data-track-category={button.label}
            data-track-action={buttonAction}
            data-track-label={button.label}
            data-action="track#send"
            onClick={() => track(button.label)}
          >
            {button.label}
          </Link>
        )}
        {link && (
          <a
            href={link.to}
            target="_blank"
            rel="noopener noreferrer"
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat"
            data-controller="track"
            data-track-category={link.label}
            data-track-action={buttonAction}
            data-track-label={link.label}
            data-action="track#send"
            onClick={() => track(link.label)}
          >
            {link.label}
          </a>
        )}
        {children && <div>{children}</div>}
      </div>
    </section>
  )
}

Feature.propTypes = {
  title: oneOfType([string, object]),
  description: string,
  button: object,
  image: string,
  imgScale: bool,
  imgAlt: string,
  inverse: bool,
  component: node,
  link: object,
  goTop: bool,
  smallMarginBottom: bool,
  textColor: string,
  imgObjectFit: string,
  wrapperStyles: object,
  imgStyle: object,
  children: node,
}

export default Feature
