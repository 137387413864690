import { graphql, useStaticQuery } from 'gatsby'

export const image = graphql`
  fragment image on File {
    childImageSharp {
      fixed(quality: 90) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`

export const accessibleImage = graphql`
  fragment accessibleImage on File {
    childImageSharp {
      fluid(maxWidth: 700, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export const imageNoLoader = graphql`
  fragment imageNoLoader on File {
    childImageSharp {
      fixed(quality: 90) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`

export const biggerImage = graphql`
  fragment biggerImage on File {
    childImageSharp {
      fluid(maxWidth: 1400, quality: 90) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export const biggerImageWithLoader = graphql`
  fragment biggerImageWithLoader on File {
    childImageSharp {
      fixed(width: 1400, quality: 90) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`

/*
How to add new image:
1. Place an image in the src/assets/images folder
2. Add new property to the query bellow:
  - property name should match filename but with camelCase notation (and without digits at the beggining)
  - for query body you could use existing fragments (above) or create one
    Tutorial: https://www.gatsbyjs.org/packages/gatsby-image
  - fixed queries are for images that have fixed dimensions
  - fluid queries are for images that will change its dimensions (like backgrouds etc)
  - use the best quality image, because it leaves the most space for optimization
  - set image quality between 60-80, these values works the best
How gatsby-image works:
- reduces images sizes
- cuts them to passed dimmensions
- creates multiple image versions for srcSet (1x, 1,5x, 2x)
- with _withWebp fragment it will also create scrSet with webp file format
- gatsby-image's Img component will fallback to png/jpg if browser is not supporting webp format
- it could attach loader to the image (with fragments):
  * _noBase64 - renders image without loader
  * _tracedSVG - will create an SVG "shadow" version of an image
  * without fragment - it will load tiny pixelated version of an image before it will load proper one
*/

export const imagesQuery = graphql`
  query {
    homepageVideo: file(relativePath: { eq: "images/homepage-video.png" }) {
      ...biggerImageWithLoader
    }
    homepageWebinar: file(relativePath: { eq: "images/homepage-webinar.png" }) {
      ...biggerImage
    }
    heroBackground: file(relativePath: { eq: "images/hero-background.png" }) {
      ...image
    }
    hero: file(relativePath: { eq: "images/hero.png" }) {
      ...biggerImage
    }
    openChatWidgetOverview: file(
      relativePath: { eq: "images/open-chat-widget-overview.png" }
    ) {
      ...biggerImage
    }
    openChatWidgetTour1: file(
      relativePath: { eq: "images/open-chat-widget-tour-1.png" }
    ) {
      ...biggerImage
    }
    openChatWidgetTour2: file(
      relativePath: { eq: "images/open-chat-widget-tour-2.png" }
    ) {
      ...biggerImage
    }
    openChatWidgetTour3: file(
      relativePath: { eq: "images/open-chat-widget-tour-3.png" }
    ) {
      ...biggerImage
    }
    customerSdk: file(relativePath: { eq: "images/customer-sdk.png" }) {
      ...biggerImage
    }
    moneyChart: file(relativePath: { eq: "images/money-chart.png" }) {
      ...image
    }
    mouseAndCard: file(relativePath: { eq: "images/card-and-mouse.png" }) {
      ...biggerImage
    }
    beautifulChat: file(relativePath: { eq: "images/beautiful-chat.png" }) {
      ...image
    }
    mobileFirst: file(relativePath: { eq: "images/mobile-first.png" }) {
      ...image
    }
    accessibleChat: file(relativePath: { eq: "images/accessible-chat.png" }) {
      ...accessibleImage
    }
    twistedLine: file(relativePath: { eq: "images/twisted-line.png" }) {
      ...image
    }
    mrMessage: file(relativePath: { eq: "images/mr-message.png" }) {
      ...image
    }
    cogUp: file(relativePath: { eq: "images/cog-up.png" }) {
      ...image
    }
    magnetMessages: file(relativePath: { eq: "images/magnet-messages.png" }) {
      ...image
    }
    chartUp: file(relativePath: { eq: "images/chart-up.png" }) {
      ...image
    }
    worldmap: file(relativePath: { eq: "images/worldmap.png" }) {
      ...image
    }
    worldHands: file(relativePath: { eq: "images/world-hands.png" }) {
      ...image
    }
    femaleAvatar: file(relativePath: { eq: "images/female-avatar.png" }) {
      ...image
    }
    maleAvatar: file(relativePath: { eq: "images/male-avatar.png" }) {
      ...image
    }
    lcsDesignSystem: file(
      relativePath: { eq: "images/lcs-design-in-practise.png" }
    ) {
      ...image
    }
    openAgentAppOverview: file(
      relativePath: { eq: "images/open-agent-app-overview.png" }
    ) {
      ...biggerImage
    }
    oneControlCenter: file(
      relativePath: { eq: "images/one-control-center.png" }
    ) {
      ...biggerImage
    }
    metrics: file(relativePath: { eq: "images/metrics.png" }) {
      ...biggerImage
    }
    designSystem: file(relativePath: { eq: "images/design-system.png" }) {
      ...biggerImage
    }
    openAgentAppTour1: file(
      relativePath: { eq: "images/open-agent-app-tour-1.png" }
    ) {
      ...biggerImage
    }
    openAgentAppTour2: file(
      relativePath: { eq: "images/open-agent-app-tour-2.png" }
    ) {
      ...biggerImage
    }
    openAgentAppTour3: file(
      relativePath: { eq: "images/open-agent-app-tour-3.png" }
    ) {
      ...biggerImage
    }
    createLead: file(relativePath: { eq: "images/create-lead.png" }) {
      ...biggerImage
    }
    twistedLine2: file(relativePath: { eq: "images/twisted-line-2.png" }) {
      ...biggerImage
    }
    dataAndReportingOverview: file(
      relativePath: { eq: "images/data-and-reporting-overview.png" }
    ) {
      ...biggerImage
    }
    csat: file(relativePath: { eq: "images/csat.png" }) {
      ...biggerImage
    }
    favouriteTools: file(relativePath: { eq: "images/favourite-tools.png" }) {
      ...biggerImage
    }
    numberOfChats: file(relativePath: { eq: "images/number-of-chats.png" }) {
      ...biggerImage
    }
    programmableChatOverview: file(
      relativePath: { eq: "images/programmable-chat-overview.png" }
    ) {
      ...biggerImage
    }
    omnichannelOverview: file(
      relativePath: { eq: "images/omnichannel-overview.png" }
    ) {
      ...biggerImage
    }
    privateAppsOverview: file(
      relativePath: { eq: "images/private-apps-overview.png" }
    ) {
      ...biggerImage
    }
    play: file(relativePath: { eq: "images/play.png" }) {
      ...imageNoLoader
    }
    pawelHalas: file(relativePath: { eq: "images/pawel-halas.png" }) {
      ...biggerImage
    }
    postTemplate: file(relativePath: { eq: "images/post-template.png" }) {
      ...biggerImage
    }
    updateCoverImage1: file(
      relativePath: { eq: "images/update-cover-image-1.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage2: file(
      relativePath: { eq: "images/update-cover-image-2.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage3: file(
      relativePath: { eq: "images/update-cover-image-3.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage4: file(
      relativePath: { eq: "images/update-cover-image-4.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage5: file(
      relativePath: { eq: "images/update-cover-image-5.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage6: file(
      relativePath: { eq: "images/update-cover-image-6.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage7: file(
      relativePath: { eq: "images/update-cover-image-7.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage8: file(
      relativePath: { eq: "images/update-cover-image-8.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage9: file(
      relativePath: { eq: "images/update-cover-image-9.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage10: file(
      relativePath: { eq: "images/update-cover-image-10.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage11: file(
      relativePath: { eq: "images/update-cover-image-11.png" }
    ) {
      ...biggerImage
    }
    updateCoverImage12: file(
      relativePath: { eq: "images/update-cover-image-12.png" }
    ) {
      ...biggerImage
    }
    developerProgramVideo: file(
      relativePath: { eq: "images/developerProgramVideo.png" }
    ) {
      ...biggerImage
    }
    buildingBlocks: file(relativePath: { eq: "images/buildingBlocks.png" }) {
      ...biggerImage
    }
    toolkit: file(relativePath: { eq: "images/toolkit.png" }) {
      ...biggerImage
    }
    readyToUseComponents: file(
      relativePath: { eq: "images/readyToUseComponents.png" }
    ) {
      ...biggerImage
    }
    avatar: file(relativePath: { eq: "images/avatar.png" }) {
      ...biggerImage
    }
    justynaJanowska: file(relativePath: { eq: "images/justyna-janowska.png" }) {
      ...biggerImage
    }
    partnerProgram: file(relativePath: { eq: "images/partner-program.jpg" }) {
      ...biggerImage
    }
    omnichannelAgentApp: file(
      relativePath: { eq: "images/omnichannel-agent-app.png" }
    ) {
      ...biggerImage
    }
    appleBusinessChat: file(
      relativePath: { eq: "images/apple-business-chat.png" }
    ) {
      ...biggerImage
    }
    allChannels: file(relativePath: { eq: "images/all-channels.png" }) {
      ...biggerImage
    }
    privateAppsTour1: file(
      relativePath: { eq: "images/private-apps-tour-1.jpg" }
    ) {
      ...biggerImage
    }
    privateAppsTour2: file(
      relativePath: { eq: "images/private-apps-tour-2.jpg" }
    ) {
      ...biggerImage
    }
    privateAppsTour3: file(
      relativePath: { eq: "images/private-apps-tour-3.jpg" }
    ) {
      ...biggerImage
    }
    buildEngagingChat: file(
      relativePath: { eq: "images/build-engaging-chat.png" }
    ) {
      ...biggerImage
    }
    secureApps: file(relativePath: { eq: "images/secure-apps.png" }) {
      ...biggerImage
    }
    automateWork: file(relativePath: { eq: "images/automate-work.png" }) {
      ...biggerImage
    }
    checkInbox: file(relativePath: { eq: "images/check-inbox.png" }) {
      ...biggerImage
    }
    jakubSikora: file(relativePath: { eq: "images/jakub-sikora.png" }) {
      ...biggerImage
    }
    dominikSlawkowski: file(
      relativePath: { eq: "images/dominik-slawkowski.png" }
    ) {
      ...biggerImage
    }
    maciejWalaszczyk: file(
      relativePath: { eq: "images/maciej-walaszczyk.png" }
    ) {
      ...biggerImage
    }
    michalCichocki: file(relativePath: { eq: "images/michal-cichocki.png" }) {
      ...biggerImage
    }
    liveCodingCover4: file(
      relativePath: { eq: "images/live-coding-cover4-blue.jpg" }
    ) {
      ...biggerImage
    }
    liveCodingBackground: file(
      relativePath: { eq: "images/live-coding-cover4-blue-2.png" }
    ) {
      ...biggerImage
    }
    privateAppsOverview2: file(
      relativePath: { eq: "images/private-apps-overview2.png" }
    ) {
      ...biggerImage
    }
    coderGray: file(relativePath: { eq: "images/coder-gray.png" }) {
      ...biggerImage
    }
    pointingFinger: file(relativePath: { eq: "images/pointing-finger.png" }) {
      ...biggerImage
    }
    appIdeasBackgroundLeft: file(
      relativePath: { eq: "images/app-ideas-background-left.png" }
    ) {
      ...biggerImage
    }
    appIdeasBackgroundRight: file(
      relativePath: { eq: "images/app-ideas-background-right.png" }
    ) {
      ...biggerImage
    }
    exploreMarketplaceLeft: file(
      relativePath: { eq: "images/explore-marketplace-left.png" }
    ) {
      ...biggerImage
    }
    exploreMarketplaceRight: file(
      relativePath: { eq: "images/explore-marketplace-right.png" }
    ) {
      ...biggerImage
    }
    bespokeCEO: file(relativePath: { eq: "images/bespoke-ceo.png" }) {
      ...biggerImage
    }
    brands: file(relativePath: { eq: "images/brands.png" }) {
      ...biggerImage
    }
    mailchimp: file(relativePath: { eq: "images/mailchimp.jpg" }) {
      ...image
    }
    whatsappBusiness: file(
      relativePath: { eq: "images/whatsapp-business.png" }
    ) {
      ...image
    }
    chatbot: file(relativePath: { eq: "images/chatbot.png" }) {
      ...image
    }
    snapcall: file(relativePath: { eq: "images/snapcall.png" }) {
      ...image
    }
    slackForLivechat: file(relativePath: { eq: "images/slack.png" }) {
      ...image
    }
    arnaudPigueller: file(relativePath: { eq: "images/arnaud-pigueller.png" }) {
      ...biggerImage
    }
    developerProgramExplainerVideo: file(
      relativePath: { eq: "images/developer-program-explainer.jpeg" }
    ) {
      ...biggerImage
    }
    appIdeasHero: file(relativePath: { eq: "images/app-ideas-hero.png" }) {
      ...biggerImage
    }
    snapcallCto: file(relativePath: { eq: "images/snapcall-cto.png" }) {
      ...biggerImage
    }
    olaKacperczyk: file(relativePath: { eq: "images/ola-kacperczyk.png" }) {
      ...biggerImage
    }
    oliwiaPolec: file(relativePath: { eq: "images/oliwia-polec.png" }) {
      ...biggerImage
    }
    arturFracala: file(relativePath: { eq: "images/artur-fracala.png" }) {
      ...biggerImage
    }
    jedrzejMasternak: file(
      relativePath: { eq: "images/jedrzej-masternak.png" }
    ) {
      ...biggerImage
    }
    kamilaTokarska: file(relativePath: { eq: "images/kamila-tokarska.png" }) {
      ...biggerImage
    }
    hdHero: file(relativePath: { eq: "images/hd-hero.png" }) {
      ...biggerImage
    }

    hdTour1: file(relativePath: { eq: "images/hd-tour-1.png" }) {
      ...biggerImage
    }
    hdTour2: file(relativePath: { eq: "images/hd-tour-2.png" }) {
      ...biggerImage
    }
    hdTour3: file(relativePath: { eq: "images/hd-tour-3.png" }) {
      ...biggerImage
    }
    jakubSikora2: file(relativePath: { eq: "images/jakub-sikora-2.png" }) {
      ...biggerImage
    }
  }
`

export const useImages = () => {
  return useStaticQuery(imagesQuery)
}
