import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import Image from '../Image'

const UpdateImage = ({ image, title, index, slug }) => (
  <Link to={`/updates/${slug}`} style={{ width: '45%' }}>
    {image ? (
      <GatsbyImage
        fluid={image.childImageSharp.fluid}
        alt={title}
        style={{
          width: '95%',
          height: '30vw',
          maxHeight: '300px',
          marginRight: '5%',
          objectFit: 'cover',
          borderRadius: '16px',
        }}
      />
    ) : (
      <Fragment>
        <Image
          type={`updateCoverImage${(index % 12) + 1}`}
          alt={title}
          imgStyles={{
            objectFit: 'cover',
            borderRadius: '16px',
          }}
          wrapperStyles={{
            width: '95%',
            height: '25vw',
            maxHeight: '300px',
            marginRight: '5%',
          }}
        />
        <span
          className="u-absolute u-text-p1 u-text-black"
          style={{ zIndex: 100, top: '5%', left: '1.5%' }}
        >
          #{index}
        </span>
      </Fragment>
    )}
  </Link>
)

UpdateImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
  index: PropTypes.number,
}

export default UpdateImage
