import React from 'react'
import useMedia from 'use-media'

const Header = () => {
  const isWide = useMedia({ minWidth: 850 })
  return (
    <section className="o-container u-Pd-xs">
      <div
        className="t-p-table t-p-first"
        style={{ marginBottom: isWide ? '' : '-80px' }}
      >
        <div className="t-p-cell-xl" />

        <div
          className="t-p-cell-md u-text-center u-border-t u-border-r"
          style={{ marginLeft: !isWide ? 'auto' : '' }}
        >
          <p className="u-text-p6-bold u-Pt-xs t-p-pricing-plans">
            For Freelancers
          </p>
          {isWide && <span>Are you looking for additional income?</span>}
        </div>

        <div className="t-p-cell-md u-text-center u-border-t u-border-r">
          <p className="u-text-p6-bold u-Pt-xs t-p-pricing-plans">
            For Integrators
          </p>
          {isWide && (
            <span>
              Are you integrating a product with the LiveChat Platform?
            </span>
          )}
        </div>

        <div className="t-p-cell-md u-text-center u-border-t u-border-r">
          <p className="u-text-p6-bold u-Pt-xs t-p-pricing-plans">
            For Innovation Partners
          </p>
          {isWide && (
            <span>Are you looking for new business opportunities?</span>
          )}
        </div>
      </div>
    </section>
  )
}

export default Header
