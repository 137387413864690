import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'
import { Link } from 'gatsby'
import Image from '../Image'

const UpdateImage = ({ image, title, index, slug, wider }) => (
  <Link to={`/updates/${slug}`} className="u-relative" style={{ width: '45%' }}>
    {image ? (
      <GatsbyImage
        fluid={image.childImageSharp.fluid}
        alt={title}
        style={{
          width: '100%',
          height: '50vw',
          maxHeight: wider ? '370px' : '340px',
          objectFit: 'cover',
          borderRadius: '16px',
        }}
      />
    ) : (
      <div className="u-relative">
        <Image
          type={`updateCoverImage${(index % 12) + 1}`}
          alt={title}
          className="u-rounded o-feature__zoom"
          imgStyles={{
            objectFit: 'cover',
            borderRadius: '16px',
          }}
          wrapperStyles={{
            width: '100%',
            maxHeight: '340px',
            height: '50vw',
          }}
        />
        <span
          className="u-absolute u-text-p1 u-text-black"
          style={{ zIndex: 1, top: '1.5%', left: '1.5%' }}
        >
          #{index}
        </span>
      </div>
    )}
  </Link>
)

UpdateImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
  index: PropTypes.number,
  wider: PropTypes.bool,
}

export default UpdateImage
