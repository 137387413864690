import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import './plan.less'

const Plan = ({
  title,
  subtitle,
  marginBottom,
  color,
  biggerTitle,
  ...props
}) => {
  const isSmall = useMedia({ maxWidth: 1000 })
  return (
    <div
      className={`o-container ${marginBottom || 'u-Mb-xl'} u-text-center ${
        isSmall ? 'plan-small' : ''
      }`}
    >
      <h3 className={`${biggerTitle ? 'u-text-p2' : 'u-text-p3'} u-mb-md`}>
        {title}
      </h3>
      <h4
        className={`${color ? `u-text-${color}-700 u-text-p5` : 'u-text-p6'} `}
      >
        {subtitle}
      </h4>
      {props.children}
    </div>
  )
}

Plan.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginBottom: PropTypes.string,
  color: PropTypes.string,
  biggerTitle: PropTypes.bool,
  children: PropTypes.node,
}

export default Plan
