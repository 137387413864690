import React from 'react'
import { Link } from 'gatsby'
import { number, string } from 'prop-types'

import ArrowLeft from '../../assets/icons/arrow-left.svg'

const Previous = ({ currentPage, category, query }) => {
  const param = query ? `?query=${query}` : ''

  return (
    <li className="pagination__item pagination__previous disabled">
      {currentPage === 1 ? (
        <div className="edge">
          <ArrowLeft />
          <span className="u-hidden md:u-inline pagination_edge">Previous</span>
        </div>
      ) : (
        <Link
          to={
            currentPage - 1 === 1
              ? `/updates/${category}/${param}`
              : `/updates/${category}/${currentPage - 1}/${param}`
          }
        >
          <ArrowLeft />
          <span className="u-hidden md:u-inline pagination_edge">Previous</span>
        </Link>
      )}
    </li>
  )
}

Previous.propTypes = {
  currentPage: number,
  category: string,
  query: string,
}

export default Previous
