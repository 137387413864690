import React from 'react'
import PropTypes from 'prop-types'

const MobileActions = ({ children }) => {
  return <div className="c-navbar__mobile-actions">{children}</div>
}

MobileActions.propTypes = {
  children: PropTypes.node,
}

export default MobileActions
