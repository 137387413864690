import React, { Fragment } from 'react'

import Numbers from '../Numbers'
import FAQ from '../FAQ'

import Header from './Header'
import SubTable from './SubTable'
import './style.less'

const LetsTalk = () => (
  <>
    <a
      href="mailto:developers@livechat.com"
      className="u-text-white u-text-underline"
    >
      Let's talk
    </a>
  </>
)

const Table = () => {
  return (
    <Fragment>
      <section className="u-Pb-md md:u-Pb-xl">
        <Header />
        <SubTable
          title="Comission"
          withBorders
          content={[
            {
              label: 'Comission from sold apps or services',
              info:
                'Place a beautiful, highly functional chat widget on your website.',
              values: ['20%', '20% or N/A', <LetsTalk />],
            },
          ]}
        />
        <SubTable
          title="Research Toolkit"
          id="research-toolkit"
          content={[
            {
              label: 'Introductory call',
              info:
                'A 20 minutes introductory call with our Developer Relations representative to discuss your ideas and needs.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Customer Community access',
              info:
                'Access to LiveChat community of customers, partners and developers.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Feature requests insights',
              info:
                'Insights and recommendations from our Developer Relations representative about what features are currently needed by our customers.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'App ideas access',
              info:
                'Access to a list of app ideas, waiting for you to be created.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'On-demand research',
              info:
                'A dedicated research aiming to collect both quantitative and qualitative feedback about area you plan to innovate in.',
              values: ['', '', 'yes'],
            },
            {
              label: 'Beta program launch',
              info:
                'We spin a guided beta launch of your app or service, so you can gather feedback and polish the details before the launch.',
              values: ['', '', 'yes'],
            },
            {
              label: 'On-demand research',
              info:
                'A 20 minutes introductory call with our Developer Relations representative to discuss your ideas and needs.',
              values: ['', '', 'yes'],
            },
          ]}
        />

        <SubTable
          title="Development Toolkit"
          id="development-toolkit"
          content={[
            {
              label: 'Developer Console Account',
              info:
                'Your own, private Developer Console account to manage and distribute all of your apps.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Full Documentation Access',
              info:
                'Access to entire documentation including developer preview releases.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Messaging APIs',
              info:
                'Acess to the messaging protocol, programmable chat, omnichannel and chat APIs. ',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Configuration APIs',
              info: 'Access to settings, automation and configuration APIs.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'SDKs & Sample Apps',
              info:
                'Access to multiple SDKs and sample apps for easy start and development convenience.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Technical support & advisory',
              info:
                "We're here to help you on the way. We have 24/7/365 support team and a dedicated technical support team to get you up and running.",
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Software architect consultancy',
              info:
                "If you're building a more complicated service, you can count on advice from our engineering teams.",
              values: ['', '', 'yes'],
            },
          ]}
        />

        <SubTable
          title="Distribution Toolkit"
          id="distribution-toolkit"
          content={[
            {
              label: 'Assisted launch in Marketplace',
              info:
                "We'll plan your launch together to make sure it's a success.",
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Monetization tools',
              info:
                'Paid apps setting with payment processing on LiveChat side and plug & play monetization API for in-app purchases.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Marketplace campaigns',
              info:
                'Multiple ways to promote your app across the entire Marketplace: home page display, staff picks and contextual promotion.',
              values: ['one time', 'quaterly', <LetsTalk />],
            },
            {
              label: 'Email campaigns',
              info:
                'Dedicated spot for your app in our newsletters and email campaigns.',
              values: ['one time', 'one time', 'quaterly'],
            },
            {
              label: 'Sales activities',
              info: 'Cooperation with our Sales Team in distibuting your app.',
              values: ['', '', 'yes'],
            },
            {
              label: 'Targeted campaigns',
              info:
                'Way to precisely reach the right target of customers with the information they need.',
              values: ['', '', 'yes'],
            },
          ]}
        />

        <SubTable
          title="Support & assistance"
          id="support"
          content={[
            {
              label: 'Async technical support',
              info:
                'Ticket-based communication with our Developer Relations representative.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Async business advisory',
              info: 'Ticket-based communication with Developer Program Team.',
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Functional tests',
              info: "We'll review the functionality of your app.",
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'Design & UX Review',
              info: "We'll review the design and user experience of your app.",
              values: ['yes', 'yes', 'yes'],
            },
            {
              label: 'App customer service',
              info:
                'L1 support for your app served by our Support Heroes Team.',
              values: ['', '', 'yes'],
            },
            {
              label: 'Private Slack channel',
              info:
                'Dedicated private channel with our Developer Relations representatives for you to discuss the partnership matters.',
              values: ['', '', 'yes'],
            },
          ]}
        />
      </section>
      <section>
        <Numbers
          items={[
            {
              title: '+30,000 companies',
              description:
                'Distribute your services to over 30k companies worldwide.',
            },
            {
              title: '+300,000 hits every minute',
              description:
                'Our chat widget is displayed 300k times every minute.',
            },
            {
              title: '+18 years on the market',
              description:
                'Our core product has been in use for over 18 years.',
            },
            {
              title: '+200 partners, +180 apps',
              description:
                'We’re a friendly company that believes in teamwork. Well, hi!',
            },
          ]}
        />
      </section>
      <FAQ
        content={[
          {
            question: 'How can I withdraw my money?',
            answer:
              'We want your earnings to be safe, so we use PayPal and wire transfers. Keep in mind that transfer fees may apply.',
          },
          {
            question: 'Are there any additional costs?',
            answer:
              'No, there are no additional costs of running your business in Developer Program at LiveChat.',
          },
          {
            question: 'Can I offer in-app payments?',
            answer:
              'Yes, we have a dedicated API for in-app purchases. You can easily create both one-time and recurrent payments.',
          },
          {
            question: 'What’s the best price for my app?',
            answer:
              "There is no secret formula, however we’re happy to talk you through our experience. Hit us up and we'll figure it out together!",
          },
          {
            question: 'What’s the potential market?',
            answer:
              'There are 30k companies which use LiveChat and tens of thousands of users who browse the Marketplace every week.',
          },
          {
            question: 'What is your refund policy?',
            answer:
              'We aim to provide fair service to all our customers. We’d like you to follow this principle for users of your app.',
          },
        ]}
      />
    </Fragment>
  )
}

export default Table
