import React, { useState, Fragment } from 'react'
import { bool } from 'prop-types'
import useMedia from 'use-media'
import MenuIcon from '../../assets/icons/menu.svg'
import CloseIcon from '../../assets/icons/close.svg'
import LiveChatLogo from '../../assets/icons/livechat-logo.svg'
import LiveChatIcon from '../../assets/icons/livechat-icon.svg'
import Profile from '../BuildingAppsCrashCourse/Profile'

import { promotion } from '../../config'
import { Actions, MobileActions, Menu, Item, Logo, Account, Back } from './'

const Header = ({ isCourseSite }) => {
  const [isExtended, setIsExtended] = useState(false)
  const [dropdown, setDropdown] = useState()
  const isWide = useMedia({ minWidth: 1024 })
  const isSmall = useMedia({ maxWidth: 415 })

  const isPromoBannerVisible =
    !isCourseSite && promotion.videoCourseBanner.isActive

  return (
    <div
      className="c-navbar u-bg-black"
      style={{
        top: isPromoBannerVisible ? '40px' : '0',
      }}
    >
      <div className="c-navbar__content">
        <Logo
          icon={isSmall ? LiveChatIcon : LiveChatLogo}
          href={process.env.GATSBY_PRODUCT_WEBSITE_URL}
        />
        <div
          className="c-navbar__button u-bg-black u-text-white"
          onClick={() => setIsExtended(!isExtended)}
        >
          Menu
          {isExtended ? (
            <CloseIcon className="u-ml-xs" />
          ) : (
            <MenuIcon className="u-ml-xs" />
          )}
        </div>

        {(isExtended || isWide) && (
          <Menu>
            {dropdown ? (
              <Fragment>
                <Back label={dropdown.label} setDropdown={setDropdown} />
                {dropdown.list.map((el, index) => {
                  return (
                    <Item
                      label={el.label}
                      to={el.to}
                      link={el.link}
                      key={`${el.label}-${index}`}
                    />
                  )
                })}
              </Fragment>
            ) : (
              <Fragment>
                <Item
                  label="Products"
                  dropdownList={[
                    { label: 'Programmable Chat', to: '/programmable-chat/' },
                    {
                      label: 'Open Chat Widget',
                      link: '/open-chat-widget/',
                      target: '_self',
                    },
                    { label: 'Open Agent App', to: '/open-agent-app/' },
                    {
                      label: 'Data & Reporting APIs',
                      to: '/data-and-reporting/',
                    },
                    { label: 'Omnichannel APIs', to: '/omnichannel/' },
                    { label: 'Private Apps', to: '/private-apps/' },
                  ]}
                  setDropdown={setDropdown}
                />
                <Item
                  label="Developer Program"
                  dropdownList={[
                    {
                      label: 'Program Overview',
                      link: `/developer-program/`,
                      target: '_self',
                    },
                    {
                      label: 'Program Offer',
                      to: `/developer-program/offer/`,
                    },
                    {
                      label: 'Why build with us?',
                      to: `/developer-program/why-build-with-livechat/`,
                    },
                    {
                      label: 'App Ideas',
                      to: `/app-ideas/`,
                    },
                    {
                      label: 'Explore Marketplace',
                      link: `${
                        process.env.GATSBY_PRODUCT_WEBSITE_URL
                      }marketplace/`,
                    },
                    {
                      label: 'Developer Academy',
                      to: `/building-apps-crash-course/`,
                    },
                  ]}
                  setDropdown={setDropdown}
                />
                <Item
                  label="Support"
                  dropdownList={[
                    {
                      label: 'Help Center',
                      to: `/support/`,
                    },
                    {
                      label: 'Updates',
                      to: `/updates/`,
                    },
                    {
                      label: 'API Status',
                      link: process.env.GATSBY_STATUS_WEBSITE_URL,
                    },
                    {
                      label: 'Developer Terms',
                      to: `/developer-terms/`,
                    },
                    {
                      label: 'Marketplace Guidelines',
                      to: `/marketplace-guidelines/`,
                    },
                  ]}
                  setDropdown={setDropdown}
                />

                <Item
                  label="Documentation"
                  dropdownList={[
                    {
                      label: 'Getting Started',
                      link: `${process.env.GATSBY_DOCS_URL}getting-started/`,
                    },
                    {
                      label: 'Authorization',
                      link: `${process.env.GATSBY_DOCS_URL}authorization/`,
                    },
                    {
                      label: 'Messaging',
                      link: `${process.env.GATSBY_DOCS_URL}messaging/`,
                    },
                    {
                      label: 'Agent App',
                      link: `${
                        process.env.GATSBY_DOCS_URL
                      }extending-agent-app/`,
                    },
                    {
                      label: 'Chat Widget',
                      link: `${
                        process.env.GATSBY_DOCS_URL
                      }extending-chat-widget/`,
                    },
                    {
                      label: 'Management',
                      link: `${process.env.GATSBY_DOCS_URL}management/`,
                    },
                    {
                      label: 'Data & Reporting',
                      link: `${process.env.GATSBY_DOCS_URL}data-reporting/`,
                    },
                    {
                      label: 'Monetization',
                      link: `${process.env.GATSBY_DOCS_URL}monetization/`,
                    },
                  ]}
                  setDropdown={setDropdown}
                />
              </Fragment>
            )}
            <MobileActions>
              {isCourseSite ? <Profile /> : <Account />}
            </MobileActions>
          </Menu>
        )}
        <Actions>{isCourseSite ? <Profile /> : <Account />}</Actions>
      </div>
    </div>
  )
}

Header.propTypes = {
  isCourseSite: bool,
}

export default Header
