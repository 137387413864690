import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { useImages } from '../hooks/useImages'

const Image = ({
  type,
  alt,
  imgStyles = {},
  wrapperStyles = {},
  className,
  ...imageProps
}) => {
  const imageData = useImages()
  const imgStyle = { objectFit: 'cover', ...imgStyles }
  const wrapperStyle = { ...wrapperStyles }

  if (imageData[type] && imageData[type].childImageSharp) {
    return (
      <Img
        alt={alt}
        className={className}
        style={wrapperStyle}
        imgStyle={imgStyle}
        {...imageData[type].childImageSharp}
        {...imageProps}
      />
    )
  }

  return null
}

Image.propTypes = {
  type: PropTypes.string,
  alt: PropTypes.string,
  imgStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  className: PropTypes.string,
}

export default Image
