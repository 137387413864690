import React from 'react'
import PropTypes from 'prop-types'
import DefaultBlock from './Block'
import JoinDevProgramBlock from './JoinDevProgramBlock'

const Blocks = ({ items }) => {
  const getBlock = (block, i) => {
    const blockKey = `${block.title || block.type}-${i}`
    switch (block.type) {
      case 'join-dev-program':
        return <JoinDevProgramBlock key={blockKey} {...block} />

      case 'default':
      default:
        return <DefaultBlock key={blockKey} {...block} />
    }
  }

  return (
    <section
      className="o-container u-Mt-md md:u-Mt-xl"
      style={{ maxWidth: 1240 }}
    >
      <div className="o-columns v--two v--responsive v--sparse">
        {items.map(getBlock)}
      </div>
    </section>
  )
}

Blocks.propTypes = {
  items: PropTypes.array,
}

export default Blocks
