import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

import Features from './Features'
import Gallery from './Gallery'

import './style.less'

const Tour = ({
  features = [],
  inverse = false,
  gray,
  noMargin,
  HD,
  imgStyles,
}) => {
  const [selected, setSelected] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const isWide = useMedia({ minWidth: 1024 })
  const isSmall = useMedia({ maxWidth: 400 })

  useEffect(
    () => {
      if (!isSmall && HD) {
        const intervalId = setInterval(() => {
          if (currentIndex === features.length - 1) {
            setCurrentIndex(0)
            setSelected(0)
          } else {
            setCurrentIndex(currentIndex + 1)
            setSelected(currentIndex + 1)
          }
        }, 8000)
        return () => clearInterval(intervalId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIndex, selected]
  )

  return (
    <section
      className={`${
        isSmall ? 'u-ml-xs' : gray ? 'tour-width' : 'o-container'
      } ${noMargin ? '' : 'u-Mb-xl xl:u-Mb-2xl'} c-tour ${
        inverse ? 'v--inverse' : ''
      } ${HD ? 'hd-tour' : ''}`}
    >
      <Features
        isSmall={isSmall}
        isWide={isWide}
        inverse={inverse}
        features={features}
        selected={selected}
        setSelected={setSelected}
        hdStyle={HD}
      />
      <Gallery
        isWide={isWide}
        image={features[selected].image}
        alt={features[selected].alt}
        imgStyles={imgStyles}
      />
    </section>
  )
}

Tour.propTypes = {
  features: PropTypes.array,
  inverse: PropTypes.bool,
  gray: PropTypes.bool,
  noMargin: PropTypes.bool,
  HD: PropTypes.bool,
  imgStyles: PropTypes.object,
}

export default Tour
