import React, { useState, useEffect } from 'react'
import { string, func, array } from 'prop-types'
import { SearchBar as LCSearchBar } from '@livechat/design-system'
import { navigate } from 'gatsby'

import './style.less'

const SearchBar = ({
  query,
  onSearchChange,
  placeholder,
  setCategory,
  category: categories,
  pageCategory,
}) => {
  const isEmpty = !query
  const [value, setValue] = useState(query)

  useEffect(() => {
    setValue(undefined)
  }, [])

  const handleSearchChange = value => {
    onSearchChange(value)
    const categoryParams =
      categories.length > 0 ? `category=${categories.join(',')}` : ''

    if (value) {
      navigate(`/updates/${categorySlug}?query=${value}&${categoryParams}`)
    } else {
      navigate(
        `/updates/${categorySlug}${categoryParams ? `?${categoryParams}` : ''}`
      )
    }
  }

  const onCategorySelect = value => {
    const formattedValue = value.toLowerCase()
    const newCategories = categories.includes(formattedValue)
      ? [...categories].filter(category => category !== formattedValue)
      : [...categories, formattedValue]

    setCategory(newCategories)

    if (newCategories.length > 0) {
      const queryParam = query ? `&query=${query}` : ''
      navigate(`/updates/all?category=${newCategories?.join(',')}${queryParam}`)
    } else {
      const queryParam = query ? `?query=${query}` : ''
      navigate(`/updates/all${queryParam}`)
    }
  }

  const categorySlug =
    pageCategory === '//' ? 'all' : pageCategory.toLowerCase()

  return (
    <div>
      <LCSearchBar
        placeholder={placeholder}
        onChange={handleSearchChange}
        className={`searchbar ${isEmpty ? 'no-clear' : ''}`}
        debounceTime={500}
        value={value}
      />

      {pageCategory === 'all' && (
        <div className="u-mt-sm">
          {['Product', 'UX', 'Programming'].map(category => (
            <button
              key={category}
              className={`c-btn u-text-p7 u-py-sm u-px-md u-bg-black-3 u-text-white ${
                categories.includes(category.toLowerCase())
                  ? 'u-border-livechat u-text-livechat'
                  : ''
              } u-mr-sm`}
              onClick={() => onCategorySelect(category)}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

SearchBar.propTypes = {
  query: string,
  onSearchChange: func,
  placeholder: string,
  setCategory: func,
  category: array,
  pageCategory: string,
}

export default SearchBar
