import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'

import ReadNextIcon from '../assets/icons/read-next.svg'

const onClick = (link, to) =>
  to ? window.open(link) : (window.location.href = link)

const More = ({
  label,
  subtitle,
  links,
  readNext = true,
  rightLink,
  button,
  buttonLink,
}) => {
  return (
    <section className="o-container">
      {label && (
        <h2 className="u-text-p3  u-w-full u-text-center u-Mb-md">{label}</h2>
      )}
      {subtitle && (
        <h3 className="u-text-p6 u-Mb-md u-w-full u-text-center u-maxw-6 u-mx-auto">
          {subtitle}
        </h3>
      )}
      <div className={`o-columns ${links.length > 2 ? 'v--three' : 'v--two'}`}>
        {links.map(
          (
            {
              title,
              description,
              link,
              linkText = 'Learn more',
              icon: Icon,
              linkOutside,
              to,
            },
            i
          ) => {
            return (
              <div
                key={`${title}-${i}`}
                onClick={() =>
                  linkOutside ? onClick(link, to) : navigate(link)
                }
                className="u-hover-lift u-hover-pointer"
              >
                <div
                  key={`${title}-${i}`}
                  className={`${
                    button ? 'u-bg-black-7 u-p-2xl' : 'u-bg-black-2 u-p-xl'
                  } u-rounded u-shadow u-h-full u-flex u-flex-col u-justify-between ${
                    i === 1 && readNext ? 'u-mt-md md:u-m-0' : ''
                  }`}
                >
                  <div className="u-Pb-xs">{Icon && <Icon />}</div>
                  <div>
                    <h3
                      className={`${
                        Icon ? 'u-text-p4' : 'u-text-p5'
                      } u-font-bold`}
                    >
                      {title}
                    </h3>
                    <h4
                      className={`${Icon ? 'u-text-gray-600' : ''} u-text-p6`}
                    >
                      {description}
                    </h4>
                  </div>
                  {linkOutside ? (
                    <a
                      href={link}
                      className="c-link u-text-livechat u-text-p6-bold"
                      target={`${to ? '_blank' : '_self'}`}
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()} //this is used to prevent both the double pop-up and the 'pop-up blocked' alert
                    >
                      {linkText}
                    </a>
                  ) : (
                    <Link
                      to={link}
                      className="c-link u-text-livechat u-text-p6-bold"
                    >
                      {linkText}
                    </Link>
                  )}
                </div>
                {i === 0 && readNext && (
                  <ReadNextIcon className="u-absolute u-ml-xl u-mt-xs md:u-mt-xl" />
                )}
              </div>
            )
          }
        )}
      </div>
      {rightLink && (
        <Link
          to={rightLink.to}
          className="u-block u-ml-auto u-mt-md md:u-mt-2xl c-link u-text-livechat u-text-p6-bold"
          style={{ width: '145px' }}
        >
          {rightLink.label}
        </Link>
      )}
      {button && (
        <div className="u-text-center u-Pt-xl">
          <Link
            to={buttonLink}
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            {button}
          </Link>
        </div>
      )}
    </section>
  )
}

More.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  readNext: PropTypes.bool,
  links: PropTypes.array,
  rightLink: PropTypes.object,
  button: PropTypes.string,
  buttonLink: PropTypes.string,
  to: PropTypes.bool,
}

export default More
