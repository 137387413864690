import React from 'react'
import Analytics from '../../utils/analytics'

const Account = () => {
  const buttonCategory = 'Go to Console'
  const buttonAction = 'Button Click'
  const buttonLabel = 'Go to Console'

  const handleClick = () => {
    Analytics.track({
      category: buttonCategory,
      action: buttonAction,
      label: buttonLabel,
    })
  }

  return (
    <a
      href={process.env.GATSBY_CONSOLE_URL}
      className="c-btn v--livechat u-text-white u-text-p6-bold"
      style={{ maxWidth: '167px' }}
      onClick={handleClick}
      data-controller="track"
      data-track-category={buttonCategory}
      data-track-action={buttonAction}
      data-track-label={buttonLabel}
      data-action="track#send"
    >
      Go to Console
    </a>
  )
}

export default Account
