import React from 'react'
import { number, string } from 'prop-types'

import './pagination.less'
import Previous from './Previous'
import Next from './Next'
import Pages from './Pages'

const Pagination = ({ currentPage, numPages, category, query }) => {
  return (
    <section className="o-container u-Mb-2xl">
      <ul className="pagination">
        <Previous currentPage={currentPage} category={category} query={query} />
        <Pages
          currentPage={currentPage}
          numPages={numPages}
          category={category}
          query={query}
        />
        <Next
          currentPage={currentPage}
          numPages={numPages}
          category={category}
          query={query}
        />
      </ul>
    </section>
  )
}

Pagination.propTypes = {
  currentPage: number,
  numPages: number,
  category: string,
  query: string,
}

export default Pagination
